import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PageLoader from "../../../page loader/PageLoader";
import Utils from "../../../utils/utils";

const limitValue = 200;

export const fetchRecordByName = createAsyncThunk(
  "records/fetchRecords",
  ({ module_name, view, sort_by = null, sort_order = null, searchText=null, limit=limitValue ,offset=0  }, { getState }) => {
    
    let moduleType = "records"

    if(module_name === "contact_lists"){
      moduleType = "broadcasts"
    }
    else{
      moduleType = "records"
    }

    let url = `/server/${moduleType}/${module_name}?view=${view}`;

    if (sort_by && sort_order) 
    {
      url += `&sort_by=${sort_by}&sort_order=${sort_order}`;
    } 
    else if(searchText !== null){
      url = `/server/${moduleType}/${module_name}?view=${view}&search_term=${searchText}`
    }
    else if(view) 
    {
      url = `/server/${moduleType}/${module_name}?view=${view}`;
      if(limit && !isNaN(limit))
      {
        url+=`&limit=${limit}`;
      }

      if(offset && !isNaN(offset))
      {
        url+=`&offset=${offset}`;
      }
    }
    else{
      url = `/server/${moduleType}/${module_name}`
    }

    return fetch(url)
      .then((response) => {
        if (response.status === 204) {
            return { [module_name]: []}
        }
        return response.json();
      })
      .then((data) => {
        if(data.status === "error"){
          throw data;
        }
        else if(!data[module_name]){
          throw({
            status:"error",
            message:`Invalid format >>>> ${data[module_name]}`,
            code:"INVALID_FORMAT"    
          })
      }
      else{
        return {
          name: module_name,
          view: view,
          record: data[module_name],
          sort: { sort_by, sort_order },
          text:searchText,
          hasmore:data.info?.has_more || false,
          limit : limit,
          offset:offset
        };
      }
      })
      .catch((error) => {
        throw error;
      });
  }
);

export const fetchSingleRecordById = createAsyncThunk(
  "records/fetchSingleRecordById",
  ({ recordType, id }, { getState }) => {
    const record = getState().record;
    const recordById = record.recordById[id];
    const isToFetchAgain = record.changedRecords.includes(id);

    if (recordById && !isToFetchAgain) {
      return Promise.resolve({ id: id, record: recordById });
    } 
    else 
    {

      let recordName = "records";

      if(recordType === "campaigns" || recordType === "contact_lists")
      {
        recordName = "broadcasts";
      }      

      let url = `/server/${recordName}/${recordType}/${id}`;
      // let url = `/server/`;
      // if(recordType === "campaigns" || recordType === "contact_lists")
      // {
      //   url += `broadcasts/${recordType}/${id}`;
      // }
      // else
      // {
      //   url += `records/${recordType}/${id}`;
      // }
      return fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if(data.status === "error")
          {
            throw data;
          }
          return { id: id, record: data };
        })
        .catch((error) => {
          throw error;
        });
    }
  }
);

export const fetchSearchRecordByName = createAsyncThunk("records/fetchSearchRecordByName",({ module_name,view, searchText = null }) => 
{   

    let url = "/server/"

    if(module_name)
    {
      if(module_name === "templates")
      {
        url += 'templates'      }
      else
      {
        let moduleType = "records";
        if(["contact_lists"].includes(module_name))
        {
          moduleType = "broadcasts";
        }
        url += `${moduleType}/${module_name}`;
      }
    }
    if(searchText)
    {
      url+=`?search_term=${searchText}`;
      if(view)
      {
        url+=`&view=${view}`;
        
      }
    }
    return fetch(url)
      .then((response) => {
        if (response.status === 204) {
          return { [module_name]: []}
        }
        return response.json();
      })
      .then((data) => {
        if(data.status === "error")
        {
          throw data;
        }
        else if(!data[module_name]){
          throw({
            status:"error",
            message:`Invalid format >>>> ${data[module_name]}`,
            code:"INVALID_FORMAT"    
          })
        }
        else{
          return {
            name:module_name,
            view:view,
            record: data[module_name],
          };
        }
      })
      .catch((error) => {
        throw error;
      });
  }
);

export const fetchRelatedRecord=createAsyncThunk("records/fetchRelatedRecord",
({recordType,recordId,relatedModule,sort_by="created_time",sort_order="desc",limit = limitValue,offset = 0},{getState})=>{     
    const { record } = getState();
    const { relatedRecordsByRecordId, changedRecords,relatedRecordshasmore } = record;
    const isToFetchAgain = changedRecords.includes(recordId);
    const relatedData = relatedRecordsByRecordId[recordId]?.[relatedModule];
    const hasmorevalue = relatedRecordshasmore;

    // Avoid fetch if data already exists and no changes have been made
    if (relatedData && !isToFetchAgain && offset === 0) {
      return Promise.resolve({
        id: recordId,
        related_module: relatedModule,
        data: relatedData,
        hasmore : hasmorevalue
      });
    }
    else
    {

      let url =  `/server/records/${recordType}/${recordId}/${relatedModule}?sort_by=${sort_by}&sort_order=${sort_order}&limit=${limit}&offset=${offset}`

      if(recordType === "contact_lists"){
        url = `/server/broadcasts/${recordType}/${recordId}/members?sort_by=${sort_by}&sort_order=${sort_order}&limit=${limit}&offset=${offset}`
      }

      return fetch(url)
            .then(response=>{
              if (response.status === 204) {
                return { 
                  id:recordId,
                  [relatedModule]: [],
                  info:
                  {
                    has_more: hasmorevalue
                  } ,
                }
              }
              return response.json();
            })
            .then((data) => {
              if(data.status === "error")
              {
                throw data;
              }
              else if(!data[relatedModule]){
                throw({
                  status:"error",
                  message:`Invalid format >>>> ${data[relatedModule]}`,
                  code:"INVALID_FORMAT"    
                })
              }
              else{
                return {
                  id:recordId,
                  related_module:relatedModule,
                  data:data[relatedModule],
                  hasmore : data.info.has_more
                }
              }
              
            })
            .catch((error) => {              
              throw error;
            });
    }
})

export const postRecord = createAsyncThunk("records/postRecord",
({ recordType, record, related_field }) => 
{
    PageLoader.start();
    let recordName = "records"

    if(recordType === "contact_lists"){
      recordName = "broadcasts"
    }

    return fetch(`/server/${recordName}/${recordType}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(record),
    })
      .then((response) => {
        PageLoader.stop();
        return response.json();
      })
      .then((data) => {
        if (data.status === "error") {
          throw (JSON.stringify({
            message:data.message,
            code: data.code,
            reason: data.reason,
            info: data.info
          }));;
        }
        return {
          type: recordType,
          record: data,
          related_field: related_field,
        };
      })
      .catch((error) => {
        PageLoader.stop();
        throw error;
      });
  }
);

export const updateRecord = createAsyncThunk(
  "records/updateRecord",
  ({ recordType, id, record, related_field }) => {
    PageLoader.start();
    let recordName = "records"

    if(recordType === "contact_lists"){
      recordName = "broadcasts"
    }

    return fetch(`/server/${recordName}/${recordType}/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(record),
    })
      .then((response) => {
        PageLoader.stop();
        return response.json();
      })
      .then((data) => {
        if (data.status === "error") {
          throw (JSON.stringify({
            message:data.message,
            code: data.code,
            reason: data.reason,
            info: data.info
          }));
        }
        return { 
          id: id, 
          type: recordType, 
          record: record, 
          related_field };
      })
      .catch((error) => {
        PageLoader.stop();
        throw error;
      });
  }
);

export const deleteRecord = createAsyncThunk(
  "records/deleteRecord",
  ({ recordType, id, related_field = null }) => {
    PageLoader.start();

    let recordName = "records"

    if(recordType === "contact_lists"){
      recordName = "broadcasts"
    }

    return fetch(`/server/${recordName}/${recordType}/${id}`, {
      method: "DELETE",
    })
      .then((response) => {
        PageLoader.stop();
        return response.json();
      })
      .then((data) => {
        if(data.status === "error")
        {
          throw data;
        }
        return { 
          id: id, 
          type: recordType, 
          related_field: related_field 
        };
      })
      .catch((error) => {
        PageLoader.stop();
        throw error;
      });
  }
);

export const pushRecordIntoIntegration = createAsyncThunk("records/pushRecordIntoIntegration",(records)=>
{
    const isMock = false;
    if(isMock)
    {
      return new Promise((resolve,reject)=>
      {
        PageLoader.start();
        setTimeout(()=>
        {
          resolve(records);
          PageLoader.stop();
        },1000)
      });
    }
    else
    {
      PageLoader.start();
      return fetch(`/server/records/contacts/actions/integration_push`,
      {
        method : "POST",
        headers : 
        {
          "Content-Type" : "Application/json"
        },
        body:JSON.stringify({record_ids:records})
      })
      .then(response=>
      {
          PageLoader.stop();
          return response.json();
      })
      .then(data=>
      {
        if(data.status === "error")
        {
          throw (JSON.stringify({
            message:data.message,
            code: data.code,
            reason: data.reason,
            info: data.info
          }));
        }
        return records;
      })
      .catch(error=>
      {
        PageLoader.stop();
        throw error;
      })
    }
})

export const getCampaignStats = createAsyncThunk("records/getCampaignStats", ({campaignId}) => {
  return fetch(`/server/broadcasts/campaigns/${campaignId}/stats`)
  .then((response) => {
    if(response.status === 204)
    {
      throw new Error("Failed to get campaign stats");
    }
    return response.json();
  })
  .then((data) => {
    if(data.status === "error")
    {
      throw new Error(data.message || "Failed to get campaign stats");
    }
    return data.stats;
  })
  .catch((error) => {
    throw error;
  });
})

export const getCampaignMessages = createAsyncThunk("records/getCampaignMessages", ({campaignId, status, limit = limitValue, offset = 0}, {getState}) => {
  const {record} = getState();
  const cachedMessages = record.campaignMessages?.[campaignId]?.[status];
  if (cachedMessages && offset === 0) 
  {
    return Promise.resolve({
      messages: cachedMessages,
      id: campaignId,
      status: status,
      hasmore: record.campaignMessagesHasmore?.[campaignId] || false,
      limit: limit,
      offset: offset,
    });
  }
  else
  {
    let url = `/server/broadcasts/campaigns/${campaignId}/messages?status=${status}`;
    if(limit && !isNaN(limit))
    {
      url+=`&limit=${limit}`;
    }

    if(offset != undefined && !isNaN(offset))
    {
      url+=`&offset=${offset}`;
    }
    return fetch(url)
    .then((response) => {
      if(response.status === 204)
      {
        return { messages : []};
      }
      return response.json();
    })
    .then((data) => {
      if(data.status === "error")
      {
        throw new Error(data.message || "Failed to get campaign messages");
      }
      return {
        messages: data.messages, 
        id: campaignId, 
        status: status,
        hasmore:data.info?.has_more || false,
        limit : limit,
        offset: offset
      };
    })
    .catch((error) => {
      throw error;
    });
  }
})

export const updateCampaignStatus = createAsyncThunk("records/updateCampaignStatus", ({campaignId, action, runtimeParams}) => {
  let options = {
    method: "PUT"
  };
  if (runtimeParams) {
    options = {
      ...options,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(runtimeParams),
    };
  }
  PageLoader.start();
  return fetch(`/server/broadcasts/campaigns/${campaignId}?action=${action}`, options)
  .then((response) => {
    PageLoader.stop();
    return response.json();
  })
  .then((data) => {
    if(data.status === "error")
    {
      throw (JSON.stringify({
        message:data.message,
        code: data.code,
        reason: data.reason,
        info: data.info
      }));
    }
    return {campaignId, action, runtimeParams};
  })
  .catch((error) => {
    PageLoader.stop();
    throw error;
  });
});

export const updateCampaign = createAsyncThunk("records/updateCampaign", ({campaignId, campaign}) => {
  let url = `/server/broadcasts/campaigns/${campaignId}`;
  let updated = {...campaign};
  PageLoader.start();
  if(campaign.formData)
  {
    return fetch(url + "/media", {
      method: "POST",
      body: campaign.formData,
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if(data.status === "error")
      {
        throw new Error(data.message || "Failed to update campaign");
      }
      updated.runtime_params = {...(updated.runtime_params || {}), _header_media : data};
      return fetch(url, { 
        method : "PUT", 
        headers : {
          "Content-Type" : "application/json"
        },
        body : JSON.stringify(updated)
      });
    })
    .then((response) => {
      PageLoader.stop();
      return response.json();
    })
    .then((data) => {
      if(data.status === "error")
      {
        throw new Error(data.message || "Failed to update campaign");
      }
      delete updated.formData;
      return {campaignId:campaignId, campaign: updated};
    })
    .catch((error) => {
      PageLoader.stop();
      throw error;
    });
  }
  else
  {
    return fetch(url, { 
      method : "PUT", 
      headers : {
        "Content-Type" : "application/json"
      },
      body : JSON.stringify(updated)
    })
    .then((response) => {
      PageLoader.stop();
      return response.json();
    })
    .then((data) => {
      if(data.status === "error")
      {
        throw new Error(data.message || "Failed to update campaign");
      }
      return {campaignId : campaignId, campaign: updated};
    })
    .catch((error) => {
      PageLoader.stop();
      throw error;
    });
  }
});

export const downloadCampaignMedia = createAsyncThunk("records/downloadCampaignMedia", ({campaignId}, {getState}) => {
    const { record } = getState();
    const base64 = record.campaignMedia[campaignId];
    if (base64)
    {
        return Promise.resolve({ id: campaignId, data: base64 });
    }
    return fetch(`/server/broadcasts/campaigns/${campaignId}/media`)
        .then((response) => {
            if (!response.ok) 
            {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.blob();
        })
        .then(blob => Utils.blobToBase64(blob))
        .then(base64 => ({ id: campaignId, data: base64 }))
        .catch(error => 
        {
            throw error;
        });
});

export const postMembers = createAsyncThunk("records/postMembers",
  ({ id, record }) => 
  {
      PageLoader.start();
     
      return fetch(`/server/broadcasts/contact_lists/${id}/members`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(record),
      })
        .then((response) => {
          PageLoader.stop();
          return response.json();
        })
        .then((data) => {
          if (data.status === "error") {
            throw (JSON.stringify({
              message:data.message,
              code: data.code,
              reason: data.reason,
              info: data.info
            }));;
          }
          return {
            id:id,
            record: data,
            module : "members"
          };
        })
        .catch((error) => {
          PageLoader.stop();
          throw error;
        });
    }
  );

const recordSlice = createSlice({
  name: "record",
  initialState: {
    records: [],
    recordByName: {},
    recordById: {},
    currentRecordViewByName: {},
    currentRecordSortedByName: {},
    recordsLoading: true,
    recordByNameLoading: {},
    singleRecordLoading: {},
    changedRecords: [],
    deletedRecords:[],
    recordsToFetchAgain: [],
    relatedRecordsByRecordId: {},
    searchRecords:{},
    campaignStats:{},
    campaignMedia:{},
    campaignMessages:{},
    searchRecordsLoading:{},
    relatedRecordsLoading: {},
    postRecordLoading: false,
    updateRecordLoading: false,
    deleteRecordLoading: false,
    pushingIntoIntegration:false,
    campaignStatsLoading:{},
    campaignMessagesLoading:{},
    campaignMediaLoading:{},
    campaignUpdating:{},
    postMembersLoading :false,
    error: {},
    searchValue :{},
    hasMoreValue : {},
    campaignMessagesHasmore:{},
    limit:limitValue,
    relatedRecordshasmore:{},
    pagination : {
      tasks :{
        firstIndex : 0,
        lastIndex : limitValue
      },
      contacts :{
        firstIndex : 0,
        lastIndex : limitValue
      },
      campaigns :{
        firstIndex : 0,
        lastIndex : limitValue
      },
      conversations :{
        firstIndex : 0,
        lastIndex : limitValue
      },
      campaign_messages : {
        campaignId : null,
        status : null,
        firstIndex : 0,
        lastIndex : limitValue
      },
      members : {
        firstIndex : 0,
        lastIndex : limitValue
      }
    }
  },
  reducers: {

    setresetdata : (state,action) =>{
      const type  = action.payload;
      state.recordByName[type] = [];
      state.pagination[type] ={
        firstIndex : 0,
        lastIndex : limitValue
      }
    },
    setResetRelatedRecord : (state, action) => {
      const { id , module } = action.payload;
      if(id && module)
      {
        delete state.relatedRecordsByRecordId[id][module];
      }
    },
    setpagination :(state,action)=>{
      const { indexes, type ,reset = false} = action.payload;
      if(reset){
        state.pagination = {
          tasks :{
            firstIndex : 0,
            lastIndex : limitValue
          },
          contacts :{
            firstIndex : 0,
            lastIndex : limitValue
          },
          campaigns :{
            firstIndex : 0,
            lastIndex : limitValue
          },
          conversations :{
            firstIndex : 0,
            lastIndex : limitValue
          },
          campaign_messages : {
            campaignId : null,
            status : null,
            firstIndex : 0,
            lastIndex : limitValue
          },
          members : {
            firstIndex : 0,
            lastIndex : limitValue
          }
      }
    }
      state.pagination[type] = indexes;
    },

    resetRecordsError:(state,action)=> {
      state.error={}
    },
    setCurrentViewByName: (state, action) => {
      const { module_name, view } = action.payload;
      state.currentRecordViewByName[module_name] = view;
    },

    setcurrentRecordSortedByName: (state, action) => {
      const { module_name, sort } = action.payload;
      state.currentRecordSortedByName[module_name] = sort;
    },

    setChangedRecords: (state, action) => {
      const id = action.payload;
      state.changedRecords.push(id);
    },
    deleteRecordById: (state,action) => 
    {
      const id = action.payload;
      if(id && state.recordById[id])
      {
        delete state.recordById[id];
      }
    },
    setRecordsToFetchAgain: (state, action) => {
      const { name, related_field } = action.payload;
      if (name) {
        if (state.recordByName[name]) {
          delete state.recordByName[name];
          state.recordsToFetchAgain.push(name);
        }
      }

      // Check and log before deleting from relatedRecordsByRecordId
      if (related_field) {
        related_field.forEach((item) => {
          if (item.existing_id &&
            state.relatedRecordsByRecordId[item.existing_id] &&
            state.relatedRecordsByRecordId[item.existing_id][item.name]
          ) {
            delete state.relatedRecordsByRecordId[item.existing_id][item.name];
          }

          if (item.id &&
            state.relatedRecordsByRecordId[item.id] &&
            state.relatedRecordsByRecordId[item.id][item.name]
          ) {
            delete state.relatedRecordsByRecordId[item.id][item.name];
          }
        });
      }
    },
    resetCampaignMessages:(state, action) => {
      const campaignid = action.payload;
      if(campaignid && state.campaignMessages[campaignid])
      {
        delete state.campaignMessages[campaignid];
      }
      state.pagination.campaign_messages = {
        campaignId : null,
        status : null,
        firstIndex : 0,
        lastIndex : limitValue
      };
    },
    includeRecord : (state, action) => {
      const { module, record } = action.payload;
      if(module && record)
      {
        if(state.recordByName[module])
        {
          const existing = state.recordByName[module]?.findIndex((i) => i.id == record.id);
          if(existing == -1)
          {
            state.recordByName[module] = [...state.recordByName[module],record];
          }
          else
          {
            state.recordByName[module][existing] = {...state.recordByName[module][existing],...record};
          }
        }
      } 
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecordByName.pending, (state, action) => {
        state.error={};
        const { module_name, view, sort_by, sort_order } = action.meta.arg;
        state.searchValue[module_name] = null;
        if (sort_by && sort_order) {
          let order = null;

          if (sort_order === "asc") {
            order = "Ascending";
          } else if (sort_order === "desc") {
            order = "Descending";
          } else {
            order = null;
          }

          state.currentRecordSortedByName[module_name] = {
            sortby: sort_by,
            value: order,
          };
        } else {
          if (state.currentRecordSortedByName[module_name]) {
            delete state.currentRecordSortedByName[module_name];
          }
        }
        state.currentRecordViewByName[module_name] = view;
        state.recordByNameLoading[module_name] = true;
      })
      .addCase(fetchRecordByName.fulfilled, (state, action) => {
        const { name, record,text,hasmore,limit,offset } = action.payload;
        state.hasMoreValue[name]=hasmore; 
        state.searchValue[name] = text;
        if (state.recordsToFetchAgain.length > 0) {
          state.recordsToFetchAgain = state.recordsToFetchAgain.filter(
            (item) => item !== name
          );
        }
        state.recordByNameLoading[name] = false;
        if(Array.isArray(state.recordByName[name]))
        {
          state.recordByName[name] = [...state.recordByName[name],...record];
        }
        else
        {
          state.recordByName[name] = record;
        }
      })
      .addCase(fetchRecordByName.rejected, (state, action) => {
        const { module_name } = action.meta.arg;
        state.searchValue[module_name] = null;
        state.recordByNameLoading[module_name] = false;
        state.recordByName[module_name] = [];
        const error=action.error;
        state.error =
        {
          origin:"fetchRecordByName",
          message:error,
        }
      })
      .addCase(fetchSingleRecordById.pending, (state, action) => {
        state.error={};
        const { id } = action.meta.arg;
        state.singleRecordLoading[id] = true;
      })
      .addCase(fetchSingleRecordById.fulfilled, (state, action) => {
        const { id, record } = action.payload;
        const recordsToFetch = state.changedRecords;

        if (recordsToFetch.includes(id)) {
          const index = recordsToFetch.indexOf(id);

          if (index > -1) {
            state.changedRecords.splice(index, 1);
          }
        }
        state.singleRecordLoading[id] = false;
        state.recordById[id] = record;
      })
      .addCase(fetchSingleRecordById.rejected, (state, action) => {
        const { id } = action.meta.arg;
        state.singleRecordLoading[id] = false;
        state.error[id] = action.error.message;
      })
      .addCase(fetchRelatedRecord.pending, (state, action) => {
        state.error={};        
        const { recordId, relatedModule } = action.meta.arg;
        if (!state.relatedRecordsLoading[recordId]) {
          state.relatedRecordsLoading[recordId] = {};
        }
        state.relatedRecordsLoading[recordId][relatedModule] = true;
      })

      .addCase(fetchRelatedRecord.fulfilled, (state, action) => {
        const { id, related_module, data,hasmore } = action.payload;                
        state.relatedRecordsLoading[id][related_module] = false;
        if(!state.relatedRecordshasmore[id])
        {
          state.relatedRecordshasmore[id] = {};
        }
        state.relatedRecordshasmore[id] = {
          ...state.relatedRecordshasmore[id],
          [related_module] : hasmore
        };
      
        // Ensure `relatedRecordsByRecordId` and the specific module exist
        if (!state.relatedRecordsByRecordId[id]) {
          state.relatedRecordsByRecordId[id] = {};
        }
        if (!state.relatedRecordsByRecordId[id][related_module]) {
          state.relatedRecordsByRecordId[id][related_module] = [];
        }
      
        // Append the new data to the existing data
        state.relatedRecordsByRecordId[id][related_module] = [
          ...state.relatedRecordsByRecordId[id][related_module],
          ...data,
        ];
      })
      .addCase(fetchRelatedRecord.rejected, (state, action) => {        
        const { recordId, relatedModule } = action.meta.arg;
        state.relatedRecordsLoading[recordId][relatedModule] = false;
        state.error[recordId] = action?.payload?.error;
      })
      .addCase(postRecord.pending, (state, action) => {
        state.error={};
        state.postRecordLoading = true;
      })
      .addCase(postRecord.fulfilled, (state, action) => {
        const { type, record, related_field } = action.payload;
        state.postRecordLoading = false;
        // Check if records exist for the type, otherwise initialize it with an empty array
        if (!state.recordByName[type]) {
          state.recordByName[type] = [];
        }

        if (related_field) {
          related_field.forEach((item) => {
            const relatedRecords =
              state.relatedRecordsByRecordId[item.id]?.[item.name];
            if (relatedRecords) {
              state.relatedRecordsByRecordId[item.id][item.name] = [
                ...relatedRecords,
                record,
              ];
            }
          });
        }
        state.recordByName[type].push(record);
      })
      .addCase(postRecord.rejected, (state, action) => {
        state.postRecordLoading = false;
        const error = action.error.message || action.error;
        state.error =
        {
          origin:"postRecord",
          message:error,
        };
        try
        {
          state.error.message = JSON.parse(error);
        }
        catch(err){
          // nothing to do
        }     
      })
      .addCase(updateRecord.pending, (state, action) => {
        state.error={};
        state.updateRecordLoading = true;
      })
      .addCase(updateRecord.fulfilled, (state, action) => {
        const { type, record, id, related_field } = action.payload;
        state.updateRecordLoading = false;
        if (state.recordByName[type]) {
          const index = state.recordByName[type].findIndex(
            (item) => item.id === id
          );
          state.recordByName[type][index] = {
            ...state.recordByName[type][index],
            ...record,
          };
        }

        if (related_field) {
          related_field.forEach((field) => {
            const { id: fieldId, name: fieldName, existing_id } = field;

            if (existing_id && (!fieldId || fieldId !== existing_id)) {
              const relatedRecords =
                state.relatedRecordsByRecordId[existing_id]?.[fieldName];
              if (relatedRecords) {
                state.relatedRecordsByRecordId[existing_id][fieldName] =
                  relatedRecords.filter((item) => item.id !== id);
              }
            } else {
              const relatedRecords =
                state.relatedRecordsByRecordId[fieldId]?.[fieldName];

              if (relatedRecords) {
                const existingRecordIndex = relatedRecords.findIndex(
                  (recordItem) => recordItem.id === id
                );

                if (existingRecordIndex !== -1) {
                  // Update the existing record with the new data
                  state.relatedRecordsByRecordId[fieldId][fieldName][
                    existingRecordIndex
                  ] = {
                    ...relatedRecords[existingRecordIndex],
                    ...record,
                    id: id,
                  };
                } else {
                  // If the record doesn't exist, append the new record
                  state.relatedRecordsByRecordId[fieldId][fieldName] = [
                    ...relatedRecords,
                    { ...record, id: id },
                  ];
                }

              }
            }
          });
        }

        if (state.recordById[id]) {
          state.recordById[id] = { ...state.recordById[id], ...record };
        }
      })
      .addCase(updateRecord.rejected, (state, action) => {
        state.updateRecordLoading = false;
        const error = action.error.message || action.error;
        state.error =
        {
          origin:"updateRecord",
          message:error,
        };
        try
        {
          state.error.message = JSON.parse(error);
        }
        catch(err){
          // nothing to do
        }
      })
      .addCase(deleteRecord.pending, (state, action) => {
        state.error={};
        state.deleteRecordLoading = true;
      })
      .addCase(deleteRecord.fulfilled, (state, action) => {
        const { type, id, related_field } = action.payload;
        state.deleteRecordLoading = false;
        state.deletedRecords = [...state.deletedRecords,id];
        if (state.recordByName[type]) {
          const updated = state.recordByName[type].filter(
            (item) => item.id !== id
          );
          state.recordByName[type] = updated;
        }

        if (state.recordById[id]) {
          delete state.recordById[id];
        }

        if (related_field) {
          related_field.forEach((item) => {
            const relatedRecords =
              state.relatedRecordsByRecordId[item.id]?.[item.name];
            if (relatedRecords) {
              state.relatedRecordsByRecordId[item.id][item.name] =
                relatedRecords.filter((item) => item.id !== id);
            }
          });
        }
      })
      .addCase(deleteRecord.rejected, (state, action) => {
        state.deleteRecordLoading = false;
        const error=action.error;
        state.error=
        {
          origin:"deleteRecord",
          message:error,
        }
      })
      .addCase(fetchSearchRecordByName.pending, (state, action) => {
        state.error={};
        const { module_name } = action.meta.arg;
        state.searchRecordsLoading[module_name] = true;
      })
      .addCase(fetchSearchRecordByName.fulfilled, (state, action) => {
        const { name,record } = action.payload;
        state.searchRecordsLoading[name] = false;
        state.searchRecords[name] = record;
      })

      .addCase(fetchSearchRecordByName.rejected, (state, action) => {
        const {module_name } = action.meta.arg;
        state.searchRecordsLoading[module_name] = false;
        state.searchRecords[module_name] = [];
        const error=action.error;
        state.error=
        {
          origin:"fetchSearchRecordByName",
          message:error,
        };
      })
      .addCase(pushRecordIntoIntegration.pending, (state, action) => {
        state.error={};
        state.pushingIntoIntegration = true;
      })
      .addCase(pushRecordIntoIntegration.fulfilled, (state, action) => {
        state.pushingIntoIntegration = false;
        const records = action.payload;
        if(Array.isArray(records))
        {
          records.forEach(id=>
          {
            if(id in state.recordById)
            {
              delete state.recordById[id];
            }
          })
        }
      })
      .addCase(pushRecordIntoIntegration.rejected, (state, action) => {
        state.pushingIntoIntegration = false;
        const error=action.error.message || action.error;
        state.error=
        {
          origin:"pushRecordIntoIntegration",
          message:error,
        };
        try
        {
          state.error.message = JSON.parse(error);
        }
        catch(err){
          // nothing to do
        }
      })
      .addCase(getCampaignStats.pending, (state, action) => {
        state.error={};
        const {campaignId} = action.meta.arg;
        state.campaignStatsLoading[campaignId] = true;
      })
      .addCase(getCampaignStats.fulfilled, (state, action) => {
        const {campaignId} = action.meta.arg;
        const stats = action.payload;
        state.campaignStatsLoading[campaignId] = false;
        state.campaignStats[campaignId] = stats;
      })
      .addCase(getCampaignStats.rejected, (state, action) => {
        const {campaignId} = action.meta.arg;
        state.campaignStatsLoading[campaignId] = false;
      })
      .addCase(getCampaignMessages.pending, (state, action) => {
        state.error={};
        const {campaignId, status, offset} = action.meta.arg;
        state.campaignMessagesLoading[campaignId] = true;
      })
      .addCase(getCampaignMessages.fulfilled, (state, action) => {
        const {id, messages, status, hasmore, limit, offset} = action.payload;
        state.campaignMessagesHasmore[id] = hasmore;
        state.campaignMessagesLoading[id] = false;
        if(!state.campaignMessages[id]) 
        {
          state.campaignMessages[id] = {};
        }
        if(!state.campaignMessages[id][status]) 
        {
          state.campaignMessages[id][status] = [];
        }
        if(offset === 0) 
        {
          state.campaignMessages[id][status] = messages;
        } 
        else 
        {
          state.campaignMessages[id][status] = Utils.getTransformedData(
            [...state.campaignMessages[id][status]],
            [...messages]
          ).data;
        }
        state.pagination.campaign_messages = 
        {
          campaignId: id,
          status: status,
          firstIndex: offset,
          lastIndex: offset + messages.length,
        };
      })
      .addCase(getCampaignMessages.rejected, (state, action) => {
        const {campaignId} = action.meta.arg;
        state.campaignMessagesLoading[campaignId] = false;
      })
      .addCase(updateCampaignStatus.pending, (state, action) => {
        state.error={};
      })
      .addCase(updateCampaignStatus.fulfilled, (state, action) => {
        const {campaignId, action:actionData, runtimeParams} = action.payload;
        if (state.recordByName["campaigns"]) {
          const index = state.recordByName["campaigns"].findIndex((item) => item.id === campaignId);
          if(index !== -1)
          {
            const updated = {
              ...state.recordByName["campaigns"][index],
              status: actionData == "run" ? "in_progress" : "stopped" 
            };
            if(runtimeParams)
            {
              updated.runtime_params = runtimeParams;
            }
            state.recordByName["campaigns"][index] = updated;
          }
        }
      })
      .addCase(updateCampaignStatus.rejected, (state, action) => {
        const error = action.error.message || action.error;
        state.error=
        {
          origin:"updateCampaignStatus",
          message:error,
        };
        try
        {
          state.error.message = JSON.parse(error);
        }
        catch(err){
          // nothing to do
        }
      })
      .addCase(updateCampaign.pending, (state, action) => {
          state.error={};
          const {campaignId}=action.meta.arg;
          state.campaignUpdating[campaignId] = true;
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        const {campaignId, campaign}=action.payload;
        state.campaignUpdating[campaignId] = false;
        if(state.recordById[campaignId])
        {
          state.recordById[campaignId] = {...state.recordById[campaignId], ...campaign};
        }
      })
      .addCase(updateCampaign.rejected, (state, action) => {
        const {campaignId}=action.meta.arg;
        state.campaignUpdating[campaignId] = false;
          const error=action.error;
          state.error=
          {
            origin:"updateCampaign",
            message:error,
          }
      })
      .addCase(downloadCampaignMedia.pending, (state,action) => {
          state.error={};
          const {campaignId}=action.meta.arg;
          state.campaignMediaLoading[campaignId] = true;
      })
      .addCase(downloadCampaignMedia.fulfilled, (state, action) => {
          const {id, data}=action.payload
          state.campaignMediaLoading[id] = false;
          state.campaignMedia[id]=data
      })
      .addCase(downloadCampaignMedia.rejected, (state, action) => {
        const {campaignId}=action.meta.arg;
        state.campaignMediaLoading[campaignId] = false;
          const error=action.error;
          state.error=
          {
            origin:"downloadCampaignMedia",
            message:error,
          }
      })
      .addCase(postMembers.pending, (state, action) => {
        state.error={};
        state.postMembersLoading = true;
      })
      .addCase(postMembers.fulfilled, (state, action) => {
        const { id, record, module } = action.payload;
        state.postMembersLoading = false;
        if(id && module)
        {
          delete state.relatedRecordsByRecordId[id][module];
        }
      })
      .addCase(postMembers.rejected, (state, action) => {
        const error = action.error.message || action.error;
        state.postMembersLoading = false;
        state.error =
        {
          origin:"postMembers",
          message:error,
        };
        try
        {
          state.error.message = JSON.parse(error);
        }
        catch(err){
          // nothing to do
        }     
      });
  },
});

export const {
  setresetdata,
  setpagination,
  resetRecordsError,
  setCurrentViewByName,
  setChangedRecords,
  setRecordsToFetchAgain,
  setcurrentRecordSortedByName,
  deleteRecordById,
  includeRecord,
  setResetRelatedRecord,
  resetCampaignMessages
} = recordSlice.actions;
export default recordSlice.reducer;