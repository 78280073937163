import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PageLoader from '../../../page loader/PageLoader';

const ORIGIN = '/server/setup/integrations';
const isMock = false; 

export const fetchIntegrations = createAsyncThunk("integrations/fetchIntegrations",()=>
{
    return fetch(ORIGIN)
    .then(response=>
    {
        return response.json();
    })
    .then(data=>
    {
        if(data.status === "error")
        {
            throw data;
        }
        else
        {
            return data; 
        }
    })
    .catch(error=>
    {
        throw error;
    });
});

export const enableIntegration = createAsyncThunk("integrations/enableIntegration",({product,body})=>
{
    if(!product)
    {
        throw new Error("Missing product: Product details are required but not found.");
    }
    else if(product && isMock)
    {
        return new Promise((resolve,reject)=>
        {
            setTimeout(()=>
            {
                fetch(ORIGIN)
                .then(response=>
                {
                    return response.json();
                })
                .then(data=>
                {
                    if(data.status === "error")
                    {
                        reject(data);
                    }
                    else
                    {
                        resolve(data); 
                    }
                })
                .catch(error=>
                {
                    reject(error);
                });
            },2000)
        });
    }
    else
    {
        return fetch(`${ORIGIN}/${product}`,
        {
            method : "POST",
            headers:
            {
                "Content-Type" : "Application/json"
            },
            body : JSON.stringify(body)
        })
        .then(response=>
        {
            return response.json();
        })
        .then(data=>
        {
            if(data.status === "error" || !data.id)
            {
                throw data;
            }
            else
            {
                return fetch(ORIGIN);
            }
        })
        .then(response=>
        {
            return response.json();
        })
        .then(data=>
        {
            if(data.status === "error")
            {
                throw data;
            }
            else
            {
                return data; 
            }
        })
        .catch(error=>
        {
            throw error;
        });
    }
});

export const updateIntegration = createAsyncThunk("integrations/updateIntegration",({product,body})=>
{
    if(!product || !body)
    {
        throw new Error("Missing product: Product name and body are required but not found.");
    }
    else if(product && body && isMock)
    {
        
        if(body.settings && "auto_push_contacts" in body.settings)
        {
            PageLoader.start();
            return new Promise((resolve,reject)=>
            {
                setTimeout(()=>
                {
                    resolve({product,body});
                    PageLoader.stop();
                },2000)
            });
        }
    }
    else
    {
        PageLoader.start();
        return fetch(ORIGIN + `/${product}`,
        {
            method : "PUT",
            headers : 
            {
                "Content-Type" : "Application/json"
            },
            body:JSON.stringify(body)
        })
        .then(response=>
        {
            PageLoader.stop();
            return response.json();
        })
        .then(data=>
        {
            if(data.status === "error")
            {
                throw data;
            }
            else
            {
                return { product, body };
            }
        })
        .catch(error=>
        {
            PageLoader.stop();
            throw error;
        })
    }
})

export const generateAPIKey = createAsyncThunk("integrations/generateAPIKey",()=>
{
    if(isMock)
    {
        
        PageLoader.start();
        return new Promise((resolve,reject)=>
        {
            setTimeout(()=>
            {
                resolve(Date.now()+"");
                PageLoader.stop();
            },2000)
        });
    }
    else
    {
        PageLoader.start();
        return fetch(ORIGIN + `/api_key`,
        {
            method : "POST",
        })
        .then(response=>
        {
            PageLoader.stop();
            return response.json();
        })
        .then(data=>
        {
            if(data.status === "error" || !data.api_key)
            {
                throw data;
            }
            else
            {
                return data.api_key;
            }
        })
        .catch(error=>
        {
            PageLoader.stop();
            throw error;
        })
    }
});

export const revokeIntegration = createAsyncThunk("integrations/revokeIntegration",(product)=>
{
    if(!product)
    {
        throw new Error("Missing product: Product details are required but not found.");
    }
    else if(product && isMock)
    {
        return new Promise((resolve,reject)=>
        {
            setTimeout(()=>
            {
              resolve(product);
            },2000)
        });
    }
    else
    {
        return fetch(`${ORIGIN}/${product}`,{ method : "DELETE" })
        .then(response=>
        {
            return response.json();
        })
        .then(data=>
        {
            if(data.error)
            {
                throw data;
            }
            else
            {
                return product;
            }
        })
        .catch(error=>
        {
            throw error;
        })
    }
});

export const revokeAPIKey = createAsyncThunk("integrations/revokeAPIKey",()=>
{
    if(isMock)
    {
        return new Promise((resolve,reject)=>
        {
            PageLoader.start();
            setTimeout(()=>
            {
                resolve();
                PageLoader.stop();
            },2000)
        });
    }
    else
    {
        PageLoader.start();
        return fetch(`${ORIGIN}/api_key`,{ method : "DELETE" })
        .then(response=>
        {
            PageLoader.stop();
            return response.json();
        })
        .then(data=>
        {
            if(data.error)
            {
                throw data;
            }
            else
            {
                return;
            }
        })
        .catch(error=>
        {
            PageLoader.stop();
            throw error;
        })
    }
});

const integrationSlice = createSlice({
    name:"integrations",
    initialState:
    {
        api_key:null,
        integrations:null,
        possible_integrations:[],
        integrationsLoading:false,
        generatingAPIKey:false,
        updatingIntegration:false,
        revokingIntegration:false,
        enablingIntegration:false,
        deletingApiKey:false,
        error:null
    },
    reducers:{},
    extraReducers:(build)=>
    {
        build
        .addCase(fetchIntegrations.pending,(state)=>
        {
            state.integrationsLoading = true;
        })
        .addCase(fetchIntegrations.fulfilled,(state,action)=>
        {
            state.integrationsLoading = false;
            const data = action.payload;
            const { api_key, integrations, meta } = data
            state.api_key = api_key || null;
            state.integrations = integrations || null;
            state.possible_integrations = meta || null;
        })
        .addCase(fetchIntegrations.rejected,(state,action)=>
        {
            state.integrationsLoading = false;
            state.error=
            {
                origin:"fetchIntegrations",
                message:action.error.message
            };
        })
        .addCase(enableIntegration.pending,(state,action)=>
        {
            state.enablingIntegration  = true;
        })
        .addCase(enableIntegration.fulfilled,(state,action)=>
        {
            const data = action.payload;
            state.enablingIntegration = false;
            state.integrations = state.integrations || [];
            if(data.integrations)
            {
                state.integrations = [...state.integrations, ...data.integrations];
            }
        })
        .addCase(enableIntegration.rejected,(state,action)=>
        {
            state.enablingIntegration  = false;
            state.error = 
            {
                origin:"enableIntegration",
                message:action.error.message || action.error
            };
        })
        .addCase(updateIntegration.pending,(state)=>
        {
            state.updatingIntegration = true;
        })
        .addCase(updateIntegration.fulfilled,(state,action)=>
        {
            const { product, body } = action.payload;
            state.updatingIntegration = false;
            state.integrations[0] = 
            {
                ...state.integrations[0],
                ...body
            }
        })
        .addCase(updateIntegration.rejected,(state,action)=>
        {
            state.updatingIntegration = false;
            state.error = 
            {
                origin:"updateIntegration",
                message:action.error.message || action.error
            };
        })
        .addCase(generateAPIKey.pending,(state)=>
        {
            state.generatingAPIKey = true;
        })
        .addCase(generateAPIKey.fulfilled,(state,action)=>
        {
            const api_key = action.payload;
            state.generatingAPIKey = false;
            state.api_key = api_key;
        })
        .addCase(generateAPIKey.rejected,(state,action)=>
        {
            state.generatingAPIKey = false;
            state.error = 
            {
                origin:"generateAPIKey",
                message:action.error.message || action.error
            };
        })
        .addCase(revokeIntegration.pending,(state,action)=>
        {
            state.revokingIntegration  = true;
        })
        .addCase(revokeIntegration.fulfilled,(state,action)=>
        {
            const product = action.payload;
            state.revokingIntegration  = false;
            if(state.integrations)
            {
                state.integrations = state.integrations.filter(item=>item.product !== product);
            }
        })
        .addCase(revokeIntegration.rejected,(state,action)=>
        {
            state.revokingIntegration  = false;
            state.error = 
            {
                origin:"revokeIntegration",
                message:action.error.message || action.error
            };
        })
        .addCase(revokeAPIKey.pending,(state,action)=>
        {
            state.deletingApiKey  = true;
        })
        .addCase(revokeAPIKey.fulfilled,(state,action)=>
        {
            state.deletingApiKey  = false;
            if(state.api_key)
            {
                state.api_key = null;
            }
        })
        .addCase(revokeAPIKey.rejected,(state,action)=>
        {
            state.deletingApiKey  = false;
            state.error = 
            {
                origin:"revokeAPIKey",
                message:action.error.message || action.error
            };
        });
    }
});

export default integrationSlice.reducer;