import { Navigate, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import LocalLoading from "./page loader/LocalLoading";
import Header from "./components/Header";

const Chats = lazy(() => import("./components/chats page/Chats"));
const Home = lazy(() => import("./components/home page/Home"));
const Contacts = lazy(() => import("./components/contacts page/Contacts"));
const Campaigns = lazy(() => import("./components/Campaigns page/Campaigns"));
const Tasks = lazy(() => import("./components/Tasks page/Tasks"));
const Analytics = lazy(() => import("./components/Analytics page/Analytics"));
const Conversationpage = lazy(() => import("./components/Conversation Page/Conversationpage"));
const TemplatesPage = lazy(() => import("./components/templates page/TemplatesPage"));
const DynamicPage = lazy(() => import("./components/dynamic page/DynamicPage"));
const Settings = lazy(() => import("./components/settings/Settings"));
const ContactListPage = lazy(() => import("./components/Contact lists page/contactListPage"));

const AppRoutes = ({ currentUser, hasChannel, channels }) => {
  return (
    <>
      <Header hasChannel={hasChannel} isActive={channels[0]?.status !== "pending"} />
      <Suspense
        fallback={
          <div className="loading">
            <LocalLoading size="80px" />
          </div>
        }
      >
        <Routes>

          {/* Chats */}
          <Route path="/" element={<Home currentUser={currentUser} hasChannel={hasChannel} channels={channels} />} />
          <Route path="/chats/*" element={<Chats />}>
            <Route path=":id" element={<Chats />} />
          </Route>

          {/* Contacts */}
          <Route path="/contacts/*" element={<Contacts />}>
            <Route index element={<DynamicPage type="contacts" />} />
            <Route path="new" element={<DynamicPage type="contacts" />} />
            <Route path=":id/edit" element={<DynamicPage type="contacts" />} />
            <Route path=":id" element={<DynamicPage type="contacts" />} />
          </Route>

           {/* Contact Lists */}
           <Route path="/contact_lists/*" element={<ContactListPage />}>
            <Route index element={<DynamicPage type="contact_lists" />} />
            <Route path="new" element={<DynamicPage type="contact_lists" />} />
            <Route path=":id/edit" element={<DynamicPage type="contact_lists" />} />
            <Route path=":id" element={<DynamicPage type="contact_lists" />} />
          </Route>

          {/* Campaigns */}
          <Route path="/campaigns/*" element={<Campaigns />}>
            <Route index element={<DynamicPage type="campaigns" />} />
            <Route path="new" element={<DynamicPage type="campaigns" />} />
            <Route path=":id/edit" element={<DynamicPage type="campaigns" />} />
            <Route path=":id" element={<DynamicPage type="campaigns" />} />
          </Route>

          {/* Conversations */}
          <Route path="/conversations/*" element={<Conversationpage />}>
            <Route index element={<DynamicPage type="conversations" />} />
            <Route path=":id/edit" element={<DynamicPage type="conversations" />} />
            <Route path=":id" element={<DynamicPage type="conversations" />} />
          </Route>

          {/* Tasks */}
          <Route path="/tasks/*" element={<Tasks />}>
            <Route index element={<DynamicPage type="tasks" />} />
            <Route path="new" element={<DynamicPage type="tasks" />} />
            <Route path=":id/edit" element={<DynamicPage type="tasks" />} />
            <Route path=":id" element={<DynamicPage type="tasks" />} />
          </Route>

          {/* Analytics */}
          <Route path="/analytics" element={<Analytics />} />

          {/* Templates */}
          <Route path="/templates/*" element={<TemplatesPage />}>
            <Route index element={<TemplatesPage />} />
            <Route path="new" element={<TemplatesPage />} />
            <Route path=":id/edit" element={<TemplatesPage />} />
            <Route path=":id" element={<TemplatesPage />} />
          </Route>

          {/* Settings */}
          <Route path="/settings/*" element={<Settings />}>
            <Route index element={<Settings />} />
            <Route path="organisation" element={<Settings />} />
            <Route path="users" element={<Settings />}>
              <Route index element={<Settings />} />
              <Route path=":id" element={<Settings />} />
            </Route>
            <Route path="whatsapp_account" element={<Settings />} />
            <Route path="contacts_onboarding" element={<Settings />} />
            <Route path="chats_automation" element={<Settings />} />
            <Route path="chat_assignment" element={<Settings />} />
          </Route>

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;