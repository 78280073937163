import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TimeZoneDropDown from "./TimeZoneDropDown";
import { postBusiness } from "../../store/feature/channels/channelSlice";
import literal from '../../assets/literals/english.json';

const { tools } = literal;
const popup_business_form = tools.popup_business_form;

const PopupBusinessForm = ({ onClose }) => {
  const { postingBusiness } = useSelector((state) => state.channel);
  const { currentUser } = useSelector((state) => state.currentuser);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    businessName: "",
    phoneNumber: "",
    timeZone: currentUser?.time_zone || "",
    agreedToTerms: false,
  });
  const [errors, setErrors] = useState({});
  
  useEffect(() => {
    if (currentUser) {
      setFormData((prev) => ({ ...prev, timeZone: currentUser?.time_zone || "" }));
    }
  }, [currentUser]);

  const handleChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBlur = (name) => {
    if (name === "businessName" && !formData.businessName) {
      setErrors((prev) => ({ ...prev, [name]: popup_business_form.empty_field }));
    }
  };

  const handleFocus = (name) => {
    const updated = { ...errors };
    if (updated[name]) {
      delete updated[name];
      setErrors(updated);
    }
  };

  const validate = () => {
    let newErrors = {};
    if (!formData.businessName) {
      newErrors.businessName = popup_business_form.empty_field;
    }
    if (!formData.agreedToTerms) {
      newErrors.agreedToTerms = popup_business_form.empty_field;
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    dispatch(postBusiness({
      body: {
        name: formData.businessName,
        time_zone: formData.timeZone
      }
    }))
    .unwrap()
    .then((response) => {
      onClose();
    })
    .catch((error) => {
      console.log(error);
    });
  };

  return (
    <div className="business-popup-form-overlay">
      <div className="business-popup-form-container">
        <h2 className="business-popup-form-title">{popup_business_form.title}</h2>
        <p className="business-popup-form-description">
          {popup_business_form.description}
        </p>

        <form onSubmit={handleSubmit} className="business-popup-form">
          <div className="business-popup-form-inputs-container">
            <div className="business-popup-form-group">
                <label className="business-popup-form-label">{popup_business_form.business_name_label}</label>
                <div className="business-popup-input-container">
                <input
                    type="text"
                    name="businessName"
                    className="business-popup-form-input"
                    autoComplete="off"
                    value={formData.businessName}
                    onBlur={(e) => handleBlur(e.target.name)}
                    onFocus={(e) => handleFocus(e.target.name)}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    style={{borderColor : errors?.businessName && "red"}}
                />
                <div className="error" style={{ visibility: errors?.businessName ? "visible" : "hidden" }}>
                    *{errors?.businessName || ""} &nbsp;
                </div>
                </div>
            </div>
            <div className="business-popup-form-group">
                <label className="business-popup-form-label">{popup_business_form.contact_phone_label}</label>
                <div className="business-popup-input-container">
                <input
                    type="text"
                    name="phoneNumber"
                    autoComplete="off"
                    className="business-popup-form-input"
                    value={formData.phoneNumber}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
                <div className="error" style={{ visibility: errors?.phoneNumber ? "visible" : "hidden" }}>
                    *{errors?.phoneNumber || ""} &nbsp;
                </div>
                </div>
            </div>
            <div className="business-popup-form-group">
                <label className="business-popup-form-label">{popup_business_form.time_zone_label}</label>
                <TimeZoneDropDown 
                    timeZoneValue={formData.timeZone}
                    onSelect={handleChange}
                    name={"timeZone"}
                />
            </div>
          </div>

          <div className="business-popup-form-checkbox-container">
            <div>
              <div>
                <input
                  type="checkbox"
                  name="agreedToTerms"
                  className="business-popup-form-checkbox"
                  onFocus={(e) => handleFocus(e.target.name)}
                  checked={formData.agreedToTerms}
                  onChange={(e) => handleChange(e.target.name, e.target.checked)}
                />
                <span className="business-popup-form-checkbox-label">
                  {popup_business_form.agree_terms_label}{" "}
                  <a href="#" target="_blank">
                    {popup_business_form.terms_of_use}
                  </a>{" "}
                  &{" "}
                  <a href="#" target="_blank">
                    {popup_business_form.privacy_policy}
                  </a>
                </span>
              </div>
              <div className="error" style={{ visibility: errors?.agreedToTerms ? "visible" : "hidden" }}>
                *{errors?.agreedToTerms || ""}&nbsp;
              </div>
            </div>
          </div>

          <button type="submit" className="business-popup-form-save-btn" disabled={postingBusiness}>
            {popup_business_form.complete_login_button}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PopupBusinessForm;