import "./toaster.css";

const Toaster = ({ title, data, description, status, onClose }) => {
  const statusColors = {
    info: "#007bff",
    success: "#28a745",
    warning: "#ffc107",
    error: "#dc3545",
  };
  const backgroundColor = statusColors[status] || "#333";

  return (
    <div className="toast-notification" style={{ backgroundColor }}>
      <div className="toast-header">
        <strong className="toast-title">{title}</strong>
        <button className="toast-close-btn" onClick={onClose}>
          ×
        </button>
      </div>
      <div className="toast-body">{description}</div>
      <pre>{JSON.stringify(data || {},null,2)}</pre>
    </div>
  );
};

export default Toaster;
