import { addNewMessage, updateMessages, addNewChat, updateChatProps } from "../store/feature/chat/chatSlice";
import { updateNotificationCount } from "../store/feature/notification/notificationSlice";
import Utils from "../utils/utils";

class NotificationHandler
{
    topics = {
        "chats" : {
            "name" : "chats",
            "events" : [ "new_chat", "update_chat", "new_messages", "message_updates", "assignment" ]
        }
    };

    constructor(dispatch,currentUser,notify)
    {
        this.dispatch = dispatch;
        this.currentUser = currentUser;
        this.notify = notify;
    }

    handleNotification(msg)
    {
        try
        {
            const messageObj = JSON.parse(msg.replace(/'/g, ""));
            const { topic, event, data } = messageObj;
            if(topic in this.topics)
            {
                if(topic === "chats")
                {
                    if(this.topics[topic].events.includes(event))
                    {
                        this.handleChatNotification(event,data);
                    }
                }
            }
        }
        catch(error)
        {
            // nothing to do
        }
    }

    handleChatNotification(event,data)
    {
        if(event === "new_chat")
        {
            this.dispatch(addNewChat({
                chat: data.chat,
                isAssignee: this.currentUser?.user_id === data?.chat?.owner?.id
            }));
        }
        else if(event === "update_chat")
        {
            this.dispatch(updateChatProps({chat:data.chat}));
        }
        else if(event === "assignment")
        {
          const notifyObj = 
          {
            title : "New Chat {{contact}} is assigned to you!".replace("{{contact}}",data?.chats?.contact?.label || ""),
          }
          this.notify(notifyObj);
        }
        else
        {
            this.handleMessageNotification(event,data);
        }
    }

    handleMessageNotification(event,data)
    {
        if (event === "new_messages") 
        {
            const newMessages = Utils.createNewMessageFromNotification(data);
            if (data.chats && data.chats.messages.length !== 0) 
            {
                const chat = {...data.chats};
                delete chat.messages;
              this.dispatch(
                addNewMessage({
                  chatId: chat.id,
                  chat: chat,
                  messages: newMessages,
                  isAssignee : this.currentUser?.user_id === chat?.owner?.id
                })
              );
              this.dispatch(
                updateNotificationCount({
                  type: "chats_nav",
                  count: newMessages.length,
                })
              );
              this.dispatch(
                updateNotificationCount({
                  type: "chats",
                  count: newMessages.length,
                })
              );
            }
            else 
            {
              console.log("empty data or chats");
            }
        } 
        else if (event === "message_updates") 
        {
            const updatedMessages = Utils.createNewMessageFromNotification(data);
            if (data.chats && data.chats.messages.length !== 0) 
            {
              this.dispatch(
                updateMessages({
                  chatId: data.chats.id,
                  messages: updatedMessages,
                })
              );
            } 
            else 
            {
              console.log("empty data or chats");
            }
        } 
        else 
        {
            console.log("notified");
        }
    }
}

export default NotificationHandler;