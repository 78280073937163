import React from "react";

const LocalLoading = ({ size = "80px", borderSize = "8px" }) => {
  return (
    <div className="local-load-body">
      <div
        className="loader"
        style={{
          width: size,
          height: size,
          border: `${borderSize} solid rgba(222, 228, 252, 1)`,
          borderTopColor: "#3D65FA",
          borderRadius: "50%",
          animation: "spin .75s linear infinite",
        }}
      ></div>
    </div>
  );
};

export default LocalLoading;
