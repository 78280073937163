import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchModules = createAsyncThunk(
  "modules/fetchModules",
  (_, { getState }) => {
    const modules = getState().module.modules;

    if (modules && modules.length !== 0) {
      return Promise.resolve(modules);
    } else {
      return fetch(`/server/modules`)
        .then((response) => {
          // if (!res.ok) {
          //   throw Error(res.statusText);
          // }
          if (response.status === 204) {
            return { modules:[]}
          }
          return response.json();
        })
        .then((data) => { 
          if(data.status === "error")
          {
            throw data;
          }
          else if(!data.modules){
            throw({
              status:"error",
              message:`Invalid format >>>> ${data.modules}`,
              code:"INVALID_FORMAT"    
            })
          }
          else{
            return data.modules
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }
);

export const fetchModule = createAsyncThunk(
  "modules/fetchModule",
  ({ name }, { getState }) => {
    const moduleByName = getState().module.moduleByName;

    if (moduleByName && moduleByName[name]) {
      return Promise.resolve({ name, module: moduleByName[name] });
    } else {
      return fetch(`/server/modules/${name}`)
        .then((response) => {
          // if (!res.ok) {
          //   throw Error(res.statusText);
          // }
          if (response.status === 204) {
            return []
          }
          return response.json();
        })
        .then((data) => {
          if(!Array.isArray(data) && data.status === "error")
            {
              throw data;
            }
            else{
              return { name, module: data };
            }
          
        })
        .catch((error) => {
          throw error;
        });
    }
  }
);

export const fetchLayoutByName = createAsyncThunk(
  "modules/fetchLayout",
  ({ name }, { getState }) => {
    const layoutByName = getState().module;
    if (layoutByName && layoutByName[name]) {
      return Promise.resolve({ name, layout: layoutByName[name] });
    } else {
      return fetch(`/server/modules/${name}/layout`)
        .then((response) => {
          // if (!res.ok) {
          //   throw Error(res.statusText);
          // }
          if (response.status === 204) {
            return {}
          }
          return response.json();
        })
        .then((data) => {
          if(data.status === "error")
          {
            throw data;
          }
          else if(!data.sections){
            throw({
              status:"error",
              message:`Invalid format >>>> ${data}`,
              code:"INVALID_FORMAT"    
            })
          }
          else{
            return { name: name, layout: data };
          }
          
        })
        .catch((error) => {
          throw error;
        });
    }
  }
);

const moduleSlice = createSlice({
  name: "module",
  initialState: {
    modules: [],
    moduleByName: {},
    layoutByName: {},
    modulesLoading: false,
    moduleByNameLoading: {},
    layoutByNameLoading: {},
    error: null,
  },
  reducers: {
    resetModuleError:(state,action)=> {
      state.error=null
  },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchModules.pending, (state) => {
        state.error=null;
        state.modulesLoading = true;
      })
      .addCase(fetchModules.fulfilled, (state, action) => {
        state.modulesLoading = false;
        state.modules = action.payload;
      })
      .addCase(fetchModules.rejected, (state, action) => {
        state.modulesLoading = false;
        const error=action.error;
        state.error=
        {
          origin:"fetchModules",
          message:error,
        }
        // state.error = action.error.message;
      })
      .addCase(fetchModule.pending, (state, action) => {
        state.error=null;
        const { name } = action.meta.arg;
        state.moduleByNameLoading[name] = true;
      })
      .addCase(fetchModule.fulfilled, (state, action) => {
        const { name, module } = action.payload;
        state.moduleByNameLoading[name] = false;
        state.moduleByName[name] = module;
      })
      .addCase(fetchModule.rejected, (state, action) => {
        const { name } = action.meta.arg;
        state.moduleByNameLoading[name] = false;
        state.moduleByName[name] = [];
        const error=action.error;
        state.error=
        {
          origin:"fetchModule",
          message:error,
        }
        // state.error = action.error.message;
      })
      .addCase(fetchLayoutByName.pending, (state, action) => {
        state.error=null;
        const { name } = action.meta.arg;
        state.layoutByNameLoading[name] = true;
      })
      .addCase(fetchLayoutByName.fulfilled, (state, action) => {
        const { name, layout } = action.payload;
        state.layoutByNameLoading[name] = false;
        state.layoutByName[name] = layout;
      })
      .addCase(fetchLayoutByName.rejected, (state, action) => {
        const { name } = action.meta.arg;
        state.layoutByNameLoading[name] = false;
        const error=action.error;
        state.error=
        {
          origin:"fetchLayoutByName",
          message:error,
        }
        // state.error = action.error.message;
      });
  },
});

export const {resetModuleError } = moduleSlice.actions;
export default moduleSlice.reducer;
