import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PageLoader from "../../../page loader/PageLoader";
import Utils from "../../../utils/utils";

const mock = true;
const ORIGIN = `/server/chats`;

export const fetchChatModule=createAsyncThunk("chats/views",(_,{getState})=>
{
  const {module}=getState().chat
  if(module && module.length!==0)
  {
    return Promise.resolve({module:module,views:module.views})
  }
  else
  {
    return fetch("/server/chats/module")
          .then((response) => {
            if (response.status === 204) {
              return { views:[]}
            }
            return response.json();
          })
          .then((data) => {
            if(data.status === "error")
            {
              throw data;
            }
            else if(!data.views)
            {
              throw ({
                status:"error",
                message:`Invalid format >>>> ${data.views}`,
                code:"INVALID_FORMAT"    
              })
            }
            else
            {
              return {module:data,views:data.views}
            }
          })
          .catch((error) => {
            throw error;
          });
  }
})

export const postChat = createAsyncThunk(
  "chat/postChat",
  ({ body }, { getState }) => {
    PageLoader.start()
    return fetch(`${ORIGIN}`, {
      method: "POST",
      headers: { "Content-Type": "Application/json" },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if(data.status === "error")
          {
            throw data;
          }
          return fetch(`/server/chats/${data.id}`)
      })
      .then( (response) =>{
        PageLoader.stop()
        return response.json()
      })
      .then((data) => {

        if(data.status === "error")
        {
          throw data;
        }
        const random = Utils.getRandomColor();
        data.color = random.color;
        data.background = random.background;
        return data
      })
      .catch((error) => {
        PageLoader.stop();
        throw error;
      });
  }
);

export const fetchSingleChat=createAsyncThunk("chat/fetchSingleChat",(id)=>
{
  return fetch(`/server/chats/${id}`)
    .then( (response) =>{
      if(response.status===204)
      {
        return null;
      }
      return response.json()
    })
    .then((data) => {
      if(data.status === "error")
      {
        throw data;
      }
      const random = Utils.getRandomColor();
      data.color = random.color;
      data.background = random.background;
      return data
    })
    .catch((error) => {
      throw error;
    })
})

export const fetchFilteredChats = createAsyncThunk("chat/fetchFilteredChats",({ query, unread,offset = 0,limit = 200,searchTerm }, { getState }) => 
{
  const initialLoad = getState().chat.initialLoad;
  if (initialLoad)
  {
    PageLoader.start();
  }
  let url = `${ORIGIN}?view=${query}&unread=${unread}&limit=${limit}&offset=${offset}`;
  if(searchTerm)
  {
    if(url.includes("?"))
    {
      url += `&search_term=${searchTerm}`;
    }
    else
    {
      url += `?search_term=${searchTerm}`;
    }
  }
  return fetch(url)
    .then((response) => {
      PageLoader.stop();
      if (response.status === 204) {
        return { chats:[]}
      } 
      return response.json();
    })
    .then((data) => {
      if(data.status === "error")
      {
        throw data;
      }
      else if (!data.chats){
        throw ({
          status:"error",
          message:data?.message || "Internal Error Occurred",
          code:"INVALID_FORMAT"    
        })
      }
      else{
          const updatedWithColors = data.chats?.map(i => 
          {
              if(typeof i === "object")
              {
                const colorObj = Utils.getRandomColor();
                i.color = colorObj.color;
                i.background = colorObj.background;
              }
              return i;
          })
        return {
          data:updatedWithColors,
          hasMore:data.info?.has_more || false,
          offset,limit,searchTerm
        };
      }
      
    })
    .catch((error) => {
      PageLoader.stop();
      throw error;
    });
  }
);

export const fetchMessages = createAsyncThunk(
  "chat/fetchMessages",
  ({ chatId, refresh = false,limit = 200,offset = 0,direction, messageId }, { getState }) => {

    const { chat } = getState();
    const messages = chat.messages[chatId];
 
    if (!refresh && messages && offset > messages?.length) {
      return Promise.resolve({ chatId, messages: messages, hasmore: false, offset, limit, direction, messageId});
    }

    let url = `/server/chats/${chatId}/messages?limit=${limit}`;
    if(messageId && direction)
    {
      url += `&message_id=${messageId}&direction=${direction}`;
    }
    if(offset)
    {
      url += `&offset=${offset}`;
    }

    return fetch(url)
      .then((response) => {
        if (response.status === 204) 
        {
          return { messages: [] };
        }
        else
        {
          return response.json();
        }
      })
      .then((data) => {
        if(data.status === "error")
        { 
          throw data;
        }
        else if(!data.messages){
          throw({
            status:"error",
            message:`Invalid format >>>> ${data.messages}`,
            code:"INVALID_FORMAT"    
          })
        }
        else{
          const messages = data.messages;
          return { 
            chatId, 
            messages: messages , 
            hasmore: data.info?.has_more || false,
            offset,
            direction,
            messageId
          };
        }

        
      })
      .catch((error) => {
        throw error;
      });
  }
);

export const updateChat = createAsyncThunk(
  "chat/updateChat",
  ({ chatId, body }) => {
    const isMock = false;    
    PageLoader.start();
    if(isMock)
    {
      PageLoader.stop();
      return Promise.resolve({ chatId, body });
    }
    else
    {
      return fetch(`/server/chats/${chatId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      })
        .then((response) => {
          PageLoader.stop();
          return response.json();
        })
        .then((data) => {
          PageLoader.stop();
          if(data.status === "error")
          {
            throw data
          }
          return { chatId, body };
        })
        .catch((error) => {
          PageLoader.stop();
          throw error;
        });
    }
  }
);

export const sendMessage = createAsyncThunk(
  "chat/sendMessage",
  (messageBody, { getState }) => {
    const { chat, content_type } = messageBody.message;
    const chatId = chat.id;
    if (
      content_type === "text" ||
      content_type === "location" ||
      content_type === "location_request"
    ) {
      const reqBody = {
        content_type: content_type,
        content: messageBody.message.content,
      };
      return fetch(`/server/chats/${chatId}/messages`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqBody),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if(data.status === "error")
          {
            throw data;
          }
          return { chatId: chatId, message: data };
        })
        .catch((error) => {
          throw error;
        });
    } else if (["file", "audio", "sticker"].includes(content_type)) {
      return fetch(`/server/chats/${chatId}/media/`, {
        method: "POST",
        body: messageBody.formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if(data.status === "error")
          {
            throw data;
          }

          const message = messageBody.message;

          // Create a new content object with the existing properties and add the id
          const newContent = {
            ...message.content[content_type],
            id: data.id,
          };

          // Create a new message object with the updated content
          const newMessage = {
            ...message,
            content: {
              ...message.content,
              [content_type]: newContent,
            },
          };

          const reqBody = {
            content_type: content_type,
            content: newMessage.content,
          };

          return fetch(`/server/chats/${chatId}/messages`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(reqBody),
          });
        })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if(data.status === "error")
          {
            throw data;
          }
          return { chatId, message: data };
        })
        .catch((error) => {
          throw error;
        });
    } else if (content_type === "template") {
      const { message, formData, metaData } = messageBody;
      if (formData) {
        return fetch(`/server/chats/${chatId}/media`, {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if(data.status === "error"){
              throw data;
            }
            // Create a new content object with the existing properties and add the id
            const newContent = {
              ...message.content[content_type],
              params: {
                ...message.content[content_type].params,
                _header_media: {
                  id: data.id,
                },
              },
            };

            // Create a new message object with the updated content
            const newMessage = {
              ...message,
              content: {
                ...message.content,
                [content_type]: newContent,
              },
            };

            const reqBody = {
              content_type: content_type,
              content: {
                [content_type] : newMessage.content[content_type]
              },
            };

            return fetch(`/server/chats/${chatId}/messages`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(reqBody),
            });
          })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data.status === "error") {
              throw data
            }
            return { chatId, message: data };
          })
          .catch((error) => {
            throw error;
          });
      } else {
        const reqBody = {
          content_type: content_type,
          content: {
            [content_type] : message.content[content_type]
          },
        };
        return fetch(`/server/chats/${chatId}/messages`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(reqBody),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data.status === "error") {
              throw data
            }
            return { chatId, message: data };
          })
          .catch((error) => {
            throw error;
          });
      }
    }
  }
);

export const fetchStarredMessages = createAsyncThunk(
  "chat/fetchStarredMessages",
  ({chatId ,limit = 200,offset = 0}, { getState }) => {
    const { chat } = getState();
    const messages = chat.starredMessages[chatId];
    const hasmore = chat.starredhasmore[chatId]
    if (messages && offset === 0) {
      return Promise.resolve({
         chatId, 
         messages: messages,
         hasmore : hasmore,
         offset : offset
         });
    }
    return fetch(`/server/chats/${chatId}/messages?type=starred&limit=${limit}&offset=${offset}`)
      .then((response) => {
        if (response.status === 204) {
          return { messages: [] };
        }
        return response.json();
      })
      .then((data) => {
        if(data.status === "error")
        {
          throw data;
        }
        else if(!data.messages){
          throw({
            status:"error",
            message:`Invalid format >>>> ${data.messages}`,
            code:"INVALID_FORMAT"    
          })
        }
        else{
          return { 
            chatId, 
            messages: data.messages,
            offset : offset,
            hasmore : data?.info?.has_more || false 
          }
        }
        
      })
      .catch((error) => {
        throw error;
      });
  }
);

export const fetchFilesInChat = createAsyncThunk(
  "chat/fetchFilesInChat",
  ({chatId,limit = 200,offset = 0}, { getState }) => {
    const { chat } = getState();
    const messages = chat.filesInChat[chatId];
    const hasmore = chat.filterhasmore[chatId]
    if (messages && offset === 0) {
      return Promise.resolve({ 
        chatId, 
        messages: messages,
        hasmore : hasmore,
        offset : offset
       });
    }
    return fetch(`/server/chats/${chatId}/messages?type=files&limit=${limit}&offset=${offset}`)
      .then((response) => {
        if (response.status === 204) {
          return { 
          chatId, 
          messages: [],
          info:
          {
            has_more: hasmore
          } ,
          offset : offset 
        };
        }
        return response.json();
      })
      .then((data) => {
        if(data.status === "error")
        {
          throw data;
        }
        else if(!data.messages){
          throw({
            status:"error",
            message:`Invalid format >>>> ${data.messages}`,
            code:"INVALID_FORMAT"    
          })
        }
        else{
          return { 
            chatId, 
            messages: data.messages,
            offset : offset,
            hasmore : data?.info?.has_more || false
           }
        }
       
      })
      .catch((error) => {
        throw error;
      });
  }
);

export const updateMessage = createAsyncThunk(
  "chat/updateMessage",
  ({ chatId, messageId, bodyValue,type,prevReaction }) => {

    var bodyValueCopy = {...bodyValue};

    if(type === "reaction"){
      delete bodyValueCopy.origin;
      delete bodyValueCopy.time;
    }
    
    if(type ==="starred"){
      PageLoader.start();
    }

    return fetch(`/server/chats/${chatId}/messages/${messageId}`, {
      method: "PUT",
      headers: { "Content-Type": "Application/json" },
      body: JSON.stringify(bodyValueCopy),
    })
      .then((response) => {
        PageLoader.stop();
        return response.json();
      })
      .then((data) => {
        if(data.status === "error")
        {
          throw data
        }
        return { chatId, messageId, bodyValue };
      })
      .catch((error) => {
        PageLoader.stop();
        throw error;
      });
  }
);

export const fetchMedia = createAsyncThunk(
  "chat/fetchMedia",
  ({ chatId, messageId }, { getState }) => {
    const { chat } = getState();
    const base64 = chat.mediaBlobs[messageId];

    if (base64) {
      return Promise.resolve({ id: messageId, chatId: chatId, data: base64 });
    }
    return fetch(`/server/chats/${chatId}/messages/${messageId}/media`)
      .then((response) => {
        if (response.status != 200) 
        {
          return response.json();
        }
        return response.blob();
      })
      .then((blob) =>
      {
        if(blob instanceof Blob)
        {
          return Utils.blobToBase64(blob);
        }
        else
        {
          throw blob;
        }
      })
      .then((base64) => ({ id: messageId, chatId: chatId, data: base64 }))
      .catch((error) => {
        throw error;
      });
  }
);

export const updateReadStatus = createAsyncThunk(
  "chat/updateRead",
  ({ chatId, read_time, lastReadMessageId }, { getState }) => {
    let readStatus = true;
    return fetch(`/server/chats/${chatId}`, {
      method: "PUT",
      headers: { "Content-Type": "Application/json" },
      body: JSON.stringify({
        read: readStatus,
        last_read_message_id: lastReadMessageId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if(data.status === "error")
          {
            throw data;
          }
        return { chatId, read_time, lastReadMessageId };
      })
      .catch((error) => {
        throw error;
      });
  }
);

export const fetchPreview = createAsyncThunk(
  "chat/preview",
  ({ chatId, messageId, url }, { getState }) => {
    const { previews } = getState().chat;
    const preview = previews[messageId];
    if (preview) {
      return Promise.resolve(preview);
    }

    return fetch(
      `https://test-840896869.development.catalystserverless.com/server/link-preview/preview?url=${encodeURIComponent(
        url
      )}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return {};
        }
        
      })
      .then((data) => {
        return { url: url, messageId: messageId, data: data };
      })
      .catch((error) => {
        throw error;
      });
  }
);

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    chatList: [],
    searchedChat:"",
    module:null,
    messages: {},
    starredMessages: {},
    filesInChat: {},
    currentChatUpdated: false,
    mediaBlobs: {},
    previews: {},
    pendingUpdate: [],
    loading: {},
    currentInputChat: {},
    currentMediaChat: {},
    views: [],
    chatViews:[],
    chatToFetch:null,
    currentView:
    {
      filter:null,
      unread:null
    },
    currentChat: null,
    initialLoad:true,
    chatMessagesLimitReached : {},
    scrollDetails:{},
    fetchChatsLoading: true,
    filterLoading:false,
    chatModuleLoading:true,
    fetchMessagesLoading: {},
    fetchStarredMessagesLoading: false,
    fetchFilesInChatLoading: false,
    fetchMediaLoading: false,
    sendMessageLoading: false,
    updateChatLoading: false,
    // updateAssigneeLoading: false,
    singleChatLoading:false,
    error: null,
    hasMore:null,
    starredhasmore : {},
    msgHasmore : {},
    filterhasmore : {},
    prevmsgLength : 0,
    prevcurrentid : null
  },
  reducers: 
  {
    setprevmsgLength : (state,action) =>{
      state.prevmsgLength = action.payload
    },
    setprevcurrentid : (state,action) =>{
      state.prevcurrentid = action.payload
    },
    setresetdata : (state) =>{
      state.chatList = [];
    },
    resetChatError:(state,action)=> {
            state.error=null
    },
    updateContactInChat: (state, action) => {
      const { id, contact } = action.payload;
      const chatList = state.chatList.map((chat) => {
        if (chat.contact.id === id) {
          return {
            ...chat,
            contact: { ...chat.contact, ...contact },
          };
        }
        return chat;
      });
      let currentChat = state.currentChat;
      if (currentChat && currentChat.contact.id === id) {
        currentChat = {
          ...currentChat,
          contact: { ...currentChat.contact, ...contact },
        };
      }
      state.chatList = chatList;
      state.currentChat = currentChat;
    },

    addNewChat(state, action)
    {
      const { chat , isAssignee } = action.payload;
      if(!state.initialLoad && Utils.toIncludeIntoChats(chat,state.currentView,isAssignee))
      {
        const chatToAdd = {...chat,...Utils.getRandomColor()};
        state.chatList = Utils.getTransformedData(state.chatList,chatToAdd).data;
      }
    },

    updateChatProps(state, action)
    {
      const { chat } = action.payload;
      const chatIndex = state.chatList.findIndex((cht) => cht.id === chat.id);
      if(chatIndex != -1)
      {
        state.chatList[chatIndex] = {...state.chatList[chatIndex],...chat};
        if(state.currentChat && state.currentChat.id === chat.id)
        {
          state.currentChat = {...state.currentChat,...chat};
        }
      }
    },

    addNewMessage: (state, action) => {
      const { chatId, messages, chat: chatFromNotification, isAssignee } = action.payload;
      const existingChatIndex = state.chatList.findIndex((chat) => chat.id === chatId);
      if (!state.initialLoad) 
      {
        if (existingChatIndex !== -1) 
        {
          const updatedChat = { ...state.chatList[existingChatIndex], ...chatFromNotification };
          state.chatList[existingChatIndex] = updatedChat;
          if (state.currentChat && state.currentChat.id === chatId) 
          {
            state.currentChat = { ...state.currentChat, ...chatFromNotification };
            state.currentChatUpdated = true;
          }
          if (messages.length > 0 && state.messages[chatId]) 
          {
            state.messages[chatId] = Utils.getTransformedData(state.messages[chatId] , messages).data;
          }
        } 
        else 
        {
          if (Utils.toIncludeIntoChats(chatFromNotification, state.currentView, isAssignee)) 
          {
            state.chatToFetch = chatId;
            if (messages.length > 0) 
            {
              state.messages[chatId] = messages;
            }
          }
        }

        if(messages.length !== 0)
        {
          const fileMessages = messages.filter((message) => ["file"].includes(message.content_type));
          if(fileMessages.length !== 0 && state.filesInChat[chatId])
          {
            state.filesInChat[chatId] = Utils.getTransformedData(state.filesInChat[chatId], fileMessages).data;
          }
        }
      }
    },
    
    updateMessages: (state, action) => {
      const { chatId, messages } = action.payload;
      const chatMessages = state.messages[chatId];
      if (chatMessages) 
      {
        const messageMap = messages.reduce((map, message) => {
          map[message.id] = message;
          return map;
        }, {});
        state.messages[chatId] = chatMessages.map((chatMessage) =>
          messageMap[chatMessage.id]
            ? { ...chatMessage, ...messageMap[chatMessage.id] }
            : chatMessage
        );
        if (messages.length !== 0) 
        {
          let starredMessages = messages.filter(message => message.starred).map((i) => i.id);
          if(starredMessages.length != 0)
          {
            starredMessages = state.messages[chatId]?.filter((message) => starredMessages.includes(message.id)).map((i) => {
              i.starred = true;
              return i;
            })
          }
          const unstarredMessageIds = messages.filter(message => !message.starred).map(msg => msg.id);
          if (state.starredMessages[chatId]) 
          {
            if (starredMessages.length !== 0) 
            {
              state.starredMessages[chatId] = Utils.getTransformedData(state.starredMessages[chatId], starredMessages).data;
            }
            if (unstarredMessageIds.length !== 0) 
            {
              state.starredMessages[chatId] = state.starredMessages[chatId].filter(message => !unstarredMessageIds.includes(message.id));
            }
          }
        }
      }
    },
    setCurrentChat: (state, action) => 
    {
      if(action.payload || action.payload === null)
      {
        state.currentChatUpdated = false;
        state.currentChat = action.payload;
      }
    },
    updateCurrentInputChat: (state, action) => {
      const { chatId, message, type } = action.payload;
      state.currentInputChat[chatId] = {
        type: type,
        text: message,
      };
    },
    updateCurrentMediaChat: (state, action) => {
      const { chatId, file } = action.payload;
      state.currentMediaChat[chatId] = file;
    },
    updateReadTime: (state, action) => {
      const { read_time, chatId } = state.pendingUpdate[0];
      const chatIndex = state.chatList.findIndex((chat) => chat.id === chatId);
      if (chatIndex !== -1) 
      {
        const chat = state.chatList[chatIndex];
        const updatedUnreadCount = "0";
        chat.unread_count = updatedUnreadCount;
        if (state.currentChat && state.currentChat.id === chatId) 
        {
          state.currentChat.unread_count = updatedUnreadCount;
        }
        state.currentChatUpdated = true;
        const updatedMessages = state.messages[chatId].map((message) => {
          if (message.origin === "incoming" && message.read_time === null) 
          {
            return { ...message, read_time: read_time };
          }
          return message;
        });
        state.messages[chatId] = updatedMessages;
        state.pendingUpdate = [];
      }
    },
    updateMediaBlobs: (state, action) => {
      const { messageId, blob } = action.payload;
      if (!messageId || !blob) {
        return;
      }

      state.mediaBlobs = {
        ...state.mediaBlobs,
        [messageId]: blob,
      };
    },
    setSearchedChat: (state,action) =>
    {
      if(typeof action.payload === "string" || action.payload == null)
      {
        if((!state.searchedChat && action.payload) || (state.searchedChat && !action.payload))
        {
          state.chatList = [];
        }
        state.searchedChat = action.payload;
      }
    }
  },

  extraReducers: (builder) => {
    builder
      // Fetch views
      .addCase(fetchChatModule.pending,(state)=>{
        state.error=null;

      })
      .addCase(fetchChatModule.fulfilled,(state,action)=>
      {
        const {module,views}=action.payload
        state.chatModuleLoading=false;
        state.chatViews=views;
        state.module=module;
      })
      .addCase(fetchChatModule.rejected,(state,action)=>
      {
        const error=action.error;
        state.chatModuleLoading=false;
        state.error=
        {
          origin:"fetchChatModule",
          message:error,
        }
      })

    // Post Chat
    .addCase(postChat.pending, (state) => {
      state.error=null;
    })
    .addCase(postChat.fulfilled, (state, action) => {
      const data =action.payload
      if(state.chatList.length !== 0)
      {
        state.chatList = [...state.chatList,data];
      }
    })
    .addCase(postChat.rejected, (state, action) => {
      const error=action.error;
      state.error=
      {
        origin:"postChat",
        message:error,
      }
    })

      // Fetch Single Chat
      .addCase(fetchSingleChat.pending,(state,action)=>
      {
        state.error=null;
        state.singleChatLoading=true;
      })
      .addCase(fetchSingleChat.fulfilled,(state,action)=>
      {
        const chat=action.payload;
        state.singleChatLoading=false;
        state.chatToFetch=null;
        if(chat)
        {
          const index=state.chatList.findIndex(item=>item.id===chat.id);
          if(index!==-1)
          {
            state.chatList[index]=chat;
          }
          else
          {
            state.chatList.push(chat);
          }
        }
      })
      .addCase(fetchSingleChat.rejected,(state,action)=>
      {
        state.singleChatLoading=false;
        state.chatToFetch = null;
        const error=action.error;
        state.error=
        {
          origin:"fetchSingleChat",
          message:error,
        }
      })
      // Fetch filtered chats
      .addCase(fetchFilteredChats.pending, (state, action) => 
      {
        state.error=null;
        state.filterLoading = true;
      })
      .addCase(fetchFilteredChats.fulfilled, (state, action) => {
        const {query,unread}=action.meta.arg;
        const { data, hasMore, limit, offset,searchTerm } = action.payload;
        
        state.filterLoading = false;
        state.currentView=
        {
          ...state.currentView,
          filter:query,
          unread:unread
        }
        if(state.initialLoad)
        {
          state.fetchChatsLoading=false;
          state.initialLoad=false;
        }
        const filteredChats = data;
        state.hasMore = hasMore;
        
        // update the state of chatList array items filteredChats items which  are present in filteredChats
        if(typeof searchTerm === "string")
        {
          state.chatList = filteredChats;
        }
        else
        {
          state.chatList = [...state.chatList,...filteredChats];
        }
        const currentChat = state.currentChat;
        if (currentChat !== null) 
        {
          const isCurrentChatInFilteredChat = state.chatList.findIndex(
            (filteredChat) => filteredChat.id === currentChat.id
          );
          if (isCurrentChatInFilteredChat === -1) {
            state.currentChat = null;
          }
        }
        state.views = filteredChats || [];
      })
      .addCase(fetchFilteredChats.rejected, (state, action) => {
        state.filterLoading = false;
        if(state.initialLoad)
        {
          state.fetchChatsLoading=false;
          state.initialLoad=false;
        }
        state.views = [];
        const error=action.error;
        state.error=
        {
          origin:"fetchFilteredChats",
          message:error,
        }
      })
      // Fetch Messages
      .addCase(fetchMessages.pending, (state,action) => {
        const { chatId } = action.meta.arg;
        state.error=null;
        state.currentChatUpdated = false;
        state.fetchMessagesLoading[chatId] = true;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        const { chatId, messages,offset,hasmore,direction,messageId } = action.payload;
        state.fetchMessagesLoading[chatId] = false;
        const existingMessages = state.messages[chatId]; 

        if(!direction ) {
          state.msgHasmore[chatId] = hasmore;
        }
        else
        {
          if(!hasmore){
            delete state.msgHasmore[chatId];
          }
        }
        if(Array.isArray(messages))
        {
          const { data, duplicateFound, duplicateCount } = Utils.getTransformedData([...(existingMessages || [])], [...messages]);          
          if(direction)
          {
            if(!duplicateFound && hasmore)
            {
              const newState = state.scrollDetails[chatId] || {};
              newState[direction+"_fetch"] = messages[messages.length-1]?.id
              newState["before_fetch"] = direction === "after" ? messageId : messages[messages.length-1]?.id;
              state.scrollDetails[chatId] = newState; 
            }
            else
            {
              //reason for duplicate: most of the time the response must include the message_id object which we send in request
              
              
              if(direction === "after" && duplicateCount <= 1 && hasmore)
              {
                const newState = state.scrollDetails[chatId] || {};
                newState[direction+"_fetch"] = messages[messages.length-1]?.id;
                state.scrollDetails[chatId] = newState; 
              }
              else
              {
                const newState = state.scrollDetails[chatId];
                if(newState && [direction+"_fetch"] in newState)
                {
                  delete newState[direction+"_fetch"];
                }
              }
            }
          }
          else
          {
            const newState = state.scrollDetails[chatId];
            if(newState && [direction+"_fetch"] in newState)
            {
              delete newState[direction+"_fetch"];
            }
          }
          state.messages[chatId] = [...data];
        }
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        const {chatId} = action.meta.arg;
         state.fetchMessagesLoading[chatId] = false;
        const error=action.error;        
        state.error=
        {
          origin:"fetchMessages",
          message:error,
        }
        // state.error = action.error.message;
      })

      // Update Chat Status
      .addCase(updateChat.pending, (state) => {
        state.error=null;
        state.updateChatLoading = true;
      })
      .addCase(updateChat.fulfilled, (state, action) => {
        state.currentChatUpdated = true;
        state.updateChatLoading = false;
        const { chatId, body } = action.payload;
        const chatIndex = state.chatList.findIndex((c) => c.id === chatId);
        if (chatIndex !== -1) {
          state.chatList[chatIndex] = {...state.chatList[chatIndex],...body};
          if (state.currentChat !== null) {
            state.currentChat = {...state.currentChat,...body};
          }
        }
      })
      .addCase(updateChat.rejected, (state, action) => {
        state.updateChatLoading = false;
        const error=action.error;
        state.error=
        {
          origin:"updateChat",
          message:error,
        }
        // state.error = action.error.message;
      })

      // Send Message
      .addCase(sendMessage.pending, (state, action) => {
        state.error=null;
        state.starredChanges = false;
        state.sendMessageLoading = true;

        const messageBody = action.meta.arg.message;
        let display_content = action.meta.arg.display_content;
        if (display_content) {
          messageBody.content.display_content = display_content;
        }
        const chatId = messageBody.chat.id;

        let chatMessages = state.messages[chatId];

        if (chatMessages) {
          delete state.currentInputChat[chatId];
          delete state.currentMediaChat[chatId];
          chatMessages = [...chatMessages, messageBody];
        } else {
          chatMessages = [messageBody];
        }

        state.messages[chatId] = chatMessages;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.sendMessageLoading = false;

        const tempId = action.meta.arg.message.id;
        const metaData = action.meta.arg.metaData;
        const { chatId, message } = action.payload;

        let chatMessages = state.messages[chatId];
        const chatIndex=state.chatList.findIndex(chat=>chat.id===chatId);
        if(chatIndex!==-1)
        {
          state.chatList[chatIndex]={...state.chatList[chatIndex],last_message_time:message.time}
        }

        if (chatMessages) {
          const pendingIndex = chatMessages.findIndex((m) => m.id === tempId);

          if (pendingIndex !== -1) {
            // Make a copy of the message to ensure immutability
            let updatedMessage = { ...message };
            if (metaData) {
              const { size, mime_type } = metaData;

              if (size) {
                if (message.content_type === "template") {
                  updatedMessage = {
                    ...updatedMessage,
                    content: {
                      ...updatedMessage.content,
                      display_content: {
                        ...updatedMessage.content.display_content,
                        header: {
                          ...updatedMessage.content.display_content.header,
                          media: {
                            ...updatedMessage.content.display_content.header
                              .media,
                            size,
                          },
                        },
                      },
                    },
                  };
                } else {
                  updatedMessage = {
                    ...updatedMessage,
                    content: {
                      ...updatedMessage.content,
                      [message.content_type]: {
                        ...updatedMessage.content[message.content_type],
                        size,
                      },
                    },
                  };
                }
              }

              if (mime_type) {
                if (message.content_type === "template") {
                  updatedMessage = {
                    ...updatedMessage,
                    content: {
                      ...updatedMessage.content,
                      display_content: {
                        ...updatedMessage.content.display_content,
                        header: {
                          ...updatedMessage.content.display_content.header,
                          media: {
                            ...updatedMessage.content.display_content.header
                              .media,
                            mime_type,
                          },
                        },
                      },
                    },
                  };
                } else {
                  updatedMessage = {
                    ...updatedMessage,
                    content: {
                      ...updatedMessage.content,
                      [message.content_type]: {
                        ...updatedMessage.content[message.content_type],
                        mime_type,
                      },
                    },
                  };
                }
              }
            }
            // Update the chatMessages with the updated message
            chatMessages[pendingIndex] = updatedMessage;
            // Update the state with the new chat messages
            state.messages[chatId] = chatMessages;
          }
        }

        if (state.mediaBlobs[tempId]) {
          state.mediaBlobs[message.id] = state.mediaBlobs[tempId];
          delete state.mediaBlobs[tempId];
        }
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.sendMessageLoading = false;
        const messageBody = action.meta.arg.message;
        const chatId = messageBody.chat.id;
        const tempId = messageBody.id;

        let chatMessages = state.messages[chatId];

        if (chatMessages) {
          const pendingIndex = chatMessages.findIndex((m) => m.id === tempId);

          if (pendingIndex !== -1) {
            chatMessages[pendingIndex].status = "failed";
          }
          state.messages[chatId] = chatMessages;
        }
        const error=action.error;
        state.error=
        {
          origin:"sendMessage",
          message:error,
        }
        // state.error = action.error.message;
      })
      // Fetch Starred Messages
      .addCase(fetchStarredMessages.pending, (state) => {
        state.error=null;
        state.fetchStarredMessagesLoading = true;
      })
      .addCase(fetchStarredMessages.fulfilled, (state, action) => {
        state.fetchStarredMessagesLoading = false;
        const { chatId, messages,offset,hasmore } = action.payload;
        state.starredhasmore[chatId] = hasmore;
        
        const existingMessages = state.starredMessages[chatId]

        if(!existingMessages || existingMessages?.length <= offset){
          if(existingMessages)
          {
            // const combined = [...existingMessages,...messages]

            // const unique = combined.filter((item, index, self) =>
            //   index === self.findIndex((obj) => obj.id === item.id)
            // );

            // state.starredMessages[chatId] = unique;
            state.starredMessages[chatId] = [...existingMessages,...messages]
          }
          else
          {
            state.starredMessages[chatId] = messages;
          }
        }
        // state.starredMessages[chatId] = messages;
      })
      .addCase(fetchStarredMessages.rejected, (state, action) => {
        state.fetchStarredMessagesLoading = false;
        const error=action.error;
        state.error=
        {
          origin:"fetchStarredMessages",
          message:error,
        }
        // state.error = action.error.message;
      })
      // Fetch Files in Chat
      .addCase(fetchFilesInChat.pending, (state) => {
        state.error=null;
        state.fetchFilesInChatLoading = true;
      })
      .addCase(fetchFilesInChat.fulfilled, (state, action) => {
        state.fetchFilesInChatLoading = false;
        const { chatId, messages,offset,hasmore } = action.payload;
        state.filterhasmore[chatId] = hasmore;
        const existingMessages = state.filesInChat[chatId];

        if(!existingMessages || existingMessages?.length <= offset){
          if(existingMessages)
          {
            state.filesInChat[chatId] = [...existingMessages,...messages]
          }
          else
          {
            state.filesInChat[chatId] = messages;
          }
        }
        // state.filesInChat[chatId] = messages;
      })
      .addCase(fetchFilesInChat.rejected, (state, action) => {
        state.fetchFilesInChatLoading = false;
        const error=action.error;
        state.error=
        {
          origin:"fetchFilesInChat",
          message:error,
        }
        // state.error = action.error.message;
      })

      // Fetch Media
      .addCase(fetchMedia.pending, (state, action) => {
        state.error=null;
        const { messageId } = action.meta.arg;
        state.loading[messageId] = true;
      })
      .addCase(fetchMedia.fulfilled, (state, action) => {
        state.fetchMediaLoading = false;
        const { id, data } = action.payload;
        state.mediaBlobs[id] = data;
        state.loading[id] = false;
      })
      .addCase(fetchMedia.rejected, (state, action) => {
        const { messageId } = action.meta.arg;
        state.loading[messageId] = false;
        const error=action.error;
        state.error=
        {
          origin:"fetchMedia",
          message:error,
        }
        // state.error = action.error.message;
      })
      // update starred
      .addCase(updateMessage.pending, (state, action) => {
        state.error=null;
        state.starredChanges = true;
        const { chatId, messageId,bodyValue,type,prevReaction } = action.meta.arg;
        const chatMessages = state.messages[chatId];
        if (chatMessages) {
          const message = chatMessages.find((m) => m.id === messageId);

          if (message) {
            if(type === "starred"){
              const starred = !message.starred;
              message.starred = starred;

                if (starred) {
                  if (state.starredMessages[chatId]) {
                    state.starredMessages[chatId].push(message);
                  }
                } else {
                  if (state.starredMessages[chatId]) {
                    state.starredMessages[chatId] = state.starredMessages[
                      chatId
                    ].filter((m) => m.id !== messageId);
                  }
                }
            }

            if(type === "reaction"){
              const body={...bodyValue}
              body.emoji=body.reaction;
              delete body.reaction;

              if(!message.reactions){
                message.reactions=[];
              }

              const reaction =  message.reactions;
              const updated=reaction.filter(reaction => reaction.origin !== body.origin).concat(body);
              message.reactions = updated;
            }
          }
        }

        state.messages[chatId] = chatMessages;
      })
      .addCase(updateMessage.fulfilled, (state, action) => {
        //do nothing as all are done in pending
      })
      .addCase(updateMessage.rejected, (state, action) => {
        const { chatId, messageId,bodyValue,type,prevReaction} = action.meta.arg;
        const error=action.error;
        const chat = state.messages[chatId];

        if (chat) {
          const message = chat.find((m) => m.id === messageId);
          if (message) {
            if(type === "starred"){
              state.error=
              {
                origin:"starred",
                message:error,
              }
              const starred = !message.starred;
              message.starred = starred;
                if (starred) {
                  if (state.starredMessages[chatId]) {
                    state.starredMessages[chatId].push(message);
                  }
                } else {
                  if (state.starredMessages[chatId]) {
                    state.starredMessages[chatId] = state.starredMessages[
                      chatId
                    ].filter((m) => m.id !== messageId);
                  }
                }
            }

            if(type === "reaction"){
              state.error=
              {
                origin:"reaction",
                message:error,
              }
                const reaction =  message.reactions
                if(prevReaction){
                  message.reactions=prevReaction;
                }
                else{
                  message.reactions = reaction.filter(reaction => reaction.time !== bodyValue.time);      
                }
            }         
          }
        }
      })
      //update read status
      .addCase(updateReadStatus.fulfilled, (state, action) => {
        const { chatId, read_time, lastReadMessageId } = action.payload;
        state.pendingUpdate = [
          ...state.pendingUpdate,
          { chatId, read_time, lastReadMessageId },
        ];
        const chat = state.chatList.find((chat) => chat.id === chatId);
        if (chat) {
          chat.unread_count = "0";
          state.currentChat.unread_count = "0";
          state.currentChatUpdated = true;
          chat.last_read_message_id = lastReadMessageId;
        }
      })
      .addCase(updateReadStatus.rejected, (state, action) => {
        const error=action.error;
        state.error=
        {
          origin:"updateReadStatus",
          message:error,
        }
        // state.error = action.error;
      })
      

      //fetch preview
      .addCase(fetchPreview.pending, (state, action) => {
        state.error=null;
        const { messageId } = action.meta.arg;
        // if (chat)
        // {
        //   const message = chat.messages.find(m => m.id === messageId);
        //   if (message)
        //   {
        //     message.isDownloading = true;

        //   }
        // }
        state.loading[messageId] = true;
      })
      .addCase(fetchPreview.fulfilled, (state, action) => {
        const { url, messageId, data } = action.payload;
        state.loading[messageId] = false;
        state.previews[messageId] = { url: url, data: data };
      })
      .addCase(fetchPreview.rejected, (state, action) => {
        const { messageId } = action.meta.arg;
        state.loading[messageId] = false;
        const error=action.error;
        state.error=
        {
          origin:"fetchPreview",
          message:error,
        }
        // state.error = action.error.message;
      });
  },
});

export const {
  setprevmsgLength,
  setprevcurrentid,
  setresetdata,
  setCurrentChat,
  updateCurrentInputChat,
  updateReadTime,
  updateCurrentMediaChat,
  updateMediaBlobs,
  addNewMessage,
  updateMessages,
  deleteChat,
  updateContactInChat,
  resetChatError,
  setSearchedChat,
  addNewChat,
  updateChatProps
} = chatSlice.actions;
export default chatSlice.reducer;
