import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PageLoader from '../../../page loader/PageLoader'
import Utils from '../../../utils/utils';

const limitValue = 200;

export const fetchTemplates=createAsyncThunk('templates/fetchTemplates',
({offset = 0,limit = limitValue , searchText = ""},{getState})=>{
    const template=getState().template;
    const hasmore = template.templatehasmore;
    const {templates, fetchAgain}=template;
    

    let url = `/server/templates?limit=${limit}&offset=${offset}`

    if (searchText?.trim() !== "") {
        url = `/server/templates?search_term=${searchText}`
    }    
    // if(templates && templates.length!==0 && !fetchAgain && offset === 0)
    // {
    //     return Promise.resolve({
    //         data: templates,
    //         hasmore :hasmore
    //     })
    // }
    // else
    // {
        return fetch(url)
        .then((response) => {
            if(response.status === 204)
            {
              return {
                templates:[],
                info:
                {
                    has_more: hasmore
                } ,
                offset:offset,
                text : searchText

            };
            }
            return response.json()
        })
        .then((data)=> {
            if(data.status === "error")
            {
                throw data;
            }
            else if(!data.templates){
                throw({
                  status:"error",
                  message:`Invalid format >>>> ${data.templates}`,
                  code:"INVALID_FORMAT"    
                })
            }
            else{
                return {
                    data:data.templates,
                    hasmore: data.info.has_more,
                    text : searchText
                }
            }
            
        })
        .catch((error) => {
            throw error;
        });
    // }
})

export const fetchSearchTemplates=createAsyncThunk('templates/fetchSearchTemplates',
    ( {searchText = ""},{getState})=>{
      
        let url = `/server/templates?search_term=${searchText}`
        return fetch(url)
        .then((response) => {
            if(response.status === 204)
            {
                return {
                templates:[],
            };
            }
            return response.json()
        })
        .then((data)=> {
            if(data.status === "error")
            {
                throw data;
            }
            else if(!data.templates){
                throw({
                    status:"error",
                    message:`Invalid format >>>> ${data.templates}`,
                    code:"INVALID_FORMAT"    
                })
            }
            else{
                return {
                    data:data.templates,
                }
            }
            
        })
        .catch((error) => {
            throw error;
        });
        
    })
    

export const fetchSingleTemplate=createAsyncThunk('templates/fetchSingleTemplate',({id},{getState})=>
{
    const template=getState().template
    const {singleTemplate,changedTemplates}=template
    if(singleTemplate && singleTemplate[id] && !changedTemplates.includes(id))
    {
        return Promise.resolve(singleTemplate[id])
    }
    else
    {
        return fetch(`/server/templates/${id}`)
        .then((response) => {
            if (response.status === 204) {
                return {}
              }
            return response.json()
        })
        .then((data)=> {
            if(data.status === "error")
            {
              throw data;
            }
            return {template:data,id:id}
        })
        .catch((error)=>{
            throw error;
        });
    }
})

export const postTemplate = createAsyncThunk('templates/postTemplate', (template) => 
{
    if (!template.headerType || template.headerType === "text") 
    {
        PageLoader.start()
        return fetch('/server/templates', { 
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(template.reqBody)
        })
        .then((response) => {
            PageLoader.stop()
            return response.json();
        })
        .then((data) => {
            if (data.status === "error") {
                throw data
            }
            return data;
        })
        .catch((error) => {
            PageLoader.stop()
            throw error;
        });
    } 
    else 
    {
        PageLoader.start()
        return fetch('/server/templates/media', { 
            method: "POST",
            body: template.formData 
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (data.status === "error") {
                throw data
            }
            template.reqBody.content.header.media = data;
            return fetch('/server/templates', 
            { 
                method: "POST",
                headers: 
                { 
                    "Content-Type": "application/json" 
                },
                body: JSON.stringify(template.reqBody)
            });
        })
        .then((response) => {
            PageLoader.stop();
            return response.json();
        })
        .then((data) => {
            if (data.status === "error") {
                throw data
            }
            return data;
        })
        .catch((error) => {
            PageLoader.stop()
            throw error;
        });
    }
});


export const editTemplate = createAsyncThunk('templates/editTemplate', ({id,template}) => 
{
    if (!template.headerType || template.headerType === "text" || !template.formData) 
    {
        PageLoader.start()
        return fetch(`/server/templates/${id}`, { 
            method: "PUT",
            headers: { "Content-Type": "application/json"},
            body: JSON.stringify(template.reqBody)
        })
        .then((response) => {
            PageLoader.stop();
            return response.json();
        })
        .then((data) => {
            if (data.status === "error") {
                throw data
            }
            return {id:id,template:template.reqBody.content};
        })
        .catch((error) => 
        {
            PageLoader.stop()
            throw error;
        });
    } 
    else 
    {
        PageLoader.start()
        return fetch('/server/templates/media', { 
            method: "POST",
            body: template.formData 
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if(data.status === "error"){
                throw data;
            }
            template.reqBody.content.header.media = data;
            return fetch(`/server/templates/${id}`, 
            { 
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(template.reqBody)
            });
        })
        .then((response) => {
            PageLoader.stop()
            return response.json();
        })
        .then((data) => {
            if(data.status === "error"){
                throw data;
            }
            return {id:id,template:template.reqBody.content};
        })
        .catch((error) => {
            PageLoader.stop()
            throw error;
        });
    }
});


export const fetchTemplateMedia=createAsyncThunk('templates/fetchTemplateMedia',({id},{getState})=>
{
    const { template } = getState();
    const base64 = template.templateMedia[id];
    if (base64) 
    {
        return Promise.resolve({ id: id, data: base64 });
    }

    return fetch(`/server/templates/${id}/media`)
        .then((response) => {
            if (!response.ok) 
            {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.blob();
        })
        .then(blob => Utils.blobToBase64(blob))
        .then(base64 => ({ id: id,data: base64 }))
        .catch(error => 
        {
            throw error;
        });
})

export const updateTemplateAction=createAsyncThunk('templates/updateTemplateAction',({id,action},{getState})=>
{
    PageLoader.start()
    return fetch(`/server/templates/${id}?action=${action}`,{ method : "PUT" })
        .then((response) => {
            PageLoader.stop()
            return response.json();
        })
        .then((data)=>{
            if(data.status==="error")
            {
                throw data
            }
            return {id,data}
            // return fetch(`/server/templates/${id}`)
        })
        // .then((response) => {
        //     PageLoader.stop()
        //     return response.json();
        // })
        // .then((data)=> {
        //     if(data.status==="error")
        //     {
        //         throw data
        //     }
        //     return {id,data}
        // })
        .catch((error) => {

            PageLoader.stop()
            throw error;
        });
})

const templateSlice = createSlice({
    name: "template",
    initialState: {
        templates: [],
        searchTemplate : [],
        templateMedia:{},
        singleTemplate:{},
        singleTemplateLoading:{},
        changedTemplates:[],
        fetchAgain:false,
        templatesLoading: false,
        searchTemplatesLoading: false,
        createTemplateLoading:false,
        editTemplateLoading:false,
        templateMediaLoading:{},
        templateActionLoading:false,
        error: null,
        templateSearchText : "",
        commonErrors :{
            ordering : false,
        },
        templatehasmore:{},
        pagination : {
            template :{
                firstIndex : 0,
                lastIndex : limitValue
            },
        },
        limit : limitValue


    },
    reducers: {

        setTemplatepagination :(state,action,reset = false)=>{
            const { indexes, type } = action.payload;            
            if(reset){                
                state.pagination = {
                    template :{
                        firstIndex : 0,
                        lastIndex : limitValue
                    },
                }
            }
            state.pagination[type] = indexes;
          },

        setresetdata : (state,action) =>{
            state.templates = [];
            state.pagination.template={
                firstIndex : 0,
                lastIndex : limitValue
            }
        },

        setCommonErrors :(state,action) =>{
           const value = action.payload
            state.commonErrors.ordering=value;
        },
        resetTemplateError:(state,action)=>
        {
            state.error=null
        },
        setMedia:(state,action)=>
        {
            const { id, media }=action.payload
            if(id && media)
            {
                state.templateMedia[id]=media
            }
        },
        setChangedTemplates:(state,action)=>
        {
            if(action.payload)
            {
                state.changedTemplates.push(action.payload)
            }
        },
        setFetchAgain:(state,action)=>
        {
            state.fetchAgain=action.payload
            if(state.templates.length>0)
            {
                state.templates=[]
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTemplates.pending, (state) => {
                state.error=null;
                state.templatesLoading = true;
                state.templateSearchText = ""
            })
            .addCase(fetchTemplates.fulfilled, (state, action) => {
                const  {data,hasmore,text} = action.payload                
                state.templateSearchText = text
                state.templatehasmore.template = hasmore;
                const existingtemplate = state.templates;
                state.templates = [...existingtemplate,...data];
                state.fetchAgain=false;
                state.templatesLoading = false;
            })
            .addCase(fetchTemplates.rejected, (state, action) => {
                state.templateSearchText = ""
                const error=action.error;
                state.error=
                {
                  origin:"fetchTemplates",
                  message:error,
                }
                state.templatesLoading = false;
            })

            // 

            .addCase(fetchSearchTemplates.pending, (state, action) => {
                state.error={};
                state.searchTemplatesLoading = true;
            })
            .addCase(fetchSearchTemplates.fulfilled, (state, action) => {
                const {data } = action.payload;
                state.searchTemplatesLoading = false;
                state.searchTemplate = data;
            })
            .addCase(fetchSearchTemplates.rejected, (state, action) => {
                state.searchTemplatesLoading = false;
                state.searchTemplate = [];
                const error=action.error;
                state.error=
                {
                    origin:"fetchSearchTemplates",
                    message:error,
                };
            })



            // 


            .addCase(fetchSingleTemplate.pending, (state,action) => {
                state.error=null;
                const {id}=action.meta.arg
                state.singleTemplateLoading[id] = true;
            })
            .addCase(fetchSingleTemplate.fulfilled, (state, action) => {
                const {template,id}=action.payload
                state.changedTemplates=state.changedTemplates.filter(item=>item!==id)
                state.singleTemplate[id] = template;
                state.singleTemplateLoading[id] = false;
            })
            .addCase(fetchSingleTemplate.rejected, (state, action) => {
                const {id}=action.meta.arg
                state.singleTemplateLoading[id] = false;
                const error=action.error;
                state.error=
                {
                  origin:"fetchSingleTemplate",
                  message:error,
                }
            })
            .addCase(postTemplate.pending, (state) => {
                state.error=null;
                state.createTemplateLoading = true;
            })
            .addCase(postTemplate.fulfilled, (state, action) => {
                state.createTemplateLoading = false;
                state.templates.push(action.payload);
            })
            .addCase(postTemplate.rejected, (state, action) => {
                state.createTemplateLoading = false;
                const error=action.error;
                state.error=
                {
                  origin:"postTemplate",
                  message:error,
                }
            })
            .addCase(editTemplate.pending, (state) => {
                state.error=null;
                state.editTemplateLoading = true;
            })
            .addCase(editTemplate.fulfilled, (state, action) => {
                state.editTemplateLoading = false;
            
                const { id, template } = action.payload;
                const templateIndex = state.templates.findIndex(item => item.id === id);
                
                if (templateIndex !== -1) 
                {
                    const updated={
                        ...state.templates[templateIndex],
                        content: template
                    };
                    state.templates[templateIndex] = updated
                }
            })
            .addCase(editTemplate.rejected, (state, action) => {
                state.editTemplateLoading = false;
                const error=action.error;
                state.error=
                {
                  origin:"editTemplate",
                  message:error,
                }
            })
            .addCase(fetchTemplateMedia.pending, (state,action) => {
                state.error=null;
                const id=action.meta.arg.id
                state.templateMediaLoading[id] = true;
            })
            .addCase(fetchTemplateMedia.fulfilled, (state, action) => {
                const {id,data}=action.payload
                state.templateMediaLoading[id] = false;
                state.templateMedia[id]=data
            })
            .addCase(fetchTemplateMedia.rejected, (state, action) => {
                const id=action.meta.arg.id
                state.templateMediaLoading[id] = false;
                const error=action.error;
                state.error=
                {
                  origin:"fetchTemplateMedia",
                  message:error,
                }
            })
            .addCase(updateTemplateAction.pending, (state) => {
                state.error=null;
                state.templateActionLoading = true;
            })
            .addCase(updateTemplateAction.fulfilled, (state, action) => {
                state.templateActionLoading = false;
            
                const { id, template } = action.payload;

                // const templateIndex = state.templates.findIndex(item => item.id === id);
                
                // if (templateIndex !== -1) {
                    // state.templates[templateIndex] = template;
                    // delete state.templates[templateIndex]
                // }
                if(state.singleTemplate[id])
                {
                    delete state.singleTemplate[id];
                }
            })
            .addCase(updateTemplateAction.rejected, (state, action) => {
                state.templateActionLoading = false;
                const error=action.error;
                state.error=
                {
                  origin:"updateTemplateAction",
                  message:error,
                }
            })
    }
});
export const { setMedia,setChangedTemplates,setFetchAgain , setCommonErrors,resetTemplateError,setTemplatepagination, setresetdata}=templateSlice.actions
export default templateSlice.reducer;