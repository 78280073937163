import React, { useEffect, useState } from "react";
import "./alertmsg.css";
import errorimg from "../app assets/icon/errorAlert.svg";
import alertCancel from "../app assets/icon/Close button.svg";
import { setCommonErrors, resetTemplateError } from "../store/feature/templates/templateSlice";
import {resetChatError} from "../store/feature/chat/chatSlice"
import {resetChannelError} from "../store/feature/channels/channelSlice"
import {resetModuleError} from "../store/feature/modules/moduleSlice"
import {resetRecordsError} from "../store/feature/records/recordSlice"
import {resetCurrentUserError} from "../store/feature/user/currentUserSlice"
import {resetRulesError} from "../store/feature/rules/ruleSlice"
// import {resetAnalyticsErrorr} from "../store/feature/analytics/analyticsSlice"

import { useDispatch } from "react-redux";
import { resetAnalyticsError } from "../store/feature/analytics/analyticsSlice";
import literal from '../assets/literals/english.json'
import { useNavigate } from "react-router-dom";

const { notification_content } = literal;
const ErrorAlert = ({ alertText, values, record, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false); // Controls visibility
  const [isHiding, setIsHiding] = useState(false); // Controls transition out
  const [timeoutId, setTimeoutId] = useState(null);
  const navigate = useNavigate()

  useEffect(() => {
    if (values === true) {
      setErrorAlert(values);
      setIsHiding(false); 
    }
  }, [values]);

  useEffect(() => {
    if (errorAlert === true && !record?.id) {
      autoHideInformationMsg();
    }
  }, [errorAlert]);

  const hideInformationMsg = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
    onClose();
    // Start the hiding transition
    setIsHiding(true);


    setTimeout(() => {
      setErrorAlert(false);
      dispatch(setCommonErrors(false));
      dispatch(resetTemplateError());
      dispatch(resetChatError())
      dispatch(resetChannelError())
      dispatch(resetModuleError())
      dispatch(resetRecordsError())
      dispatch(resetCurrentUserError())
      dispatch(resetRulesError())
      dispatch(resetAnalyticsError())
    }, 500); 
  };

  function autoHideInformationMsg() {
    const timerID = setTimeout(() => {
      hideInformationMsg(); // Initiates the hide with animation
    }, 4000);

    setTimeoutId(timerID);
  }

  function handleNavigation()
  {
    hideInformationMsg();
    navigate(`/contacts/${record?.id}`);
  }

  return (
    <>
      <div className="alert-message-body">
        <div
          className={`alert-msg-sub-body error-body ${
            errorAlert ? (isHiding ? "alert-msg-up" : "alert-msg-down") : ""
          }`}
        >
          <div className="alert-msg-icon error-alert-msg-icon">
            <img src={errorimg} alt="info" />
          </div>

          <div>
          <div className="alert-msg-title">{notification_content.error_label} </div>
            <div className="alert-msg-content">
              {alertText} {record?.label && <> - <span className="alert-msg-navigate-text" onClick={() => handleNavigation()}>{record?.label}</span></>}
            </div>
          </div>
          
          <div
            className="alert-msg-cancel"
            onClick={() => {
              hideInformationMsg();
            }}
          >
            <img src={alertCancel} alt="cancel" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorAlert;
