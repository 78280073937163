import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PageLoader from '../../../page loader/PageLoader';

export const fetchBusiness = createAsyncThunk("channels/fetchBusiness", () => {
    return fetch(`/server/setup/business`).then((res) => {
        return res.json();
    })
    .then((data) => {
        if(data.status == "error")
        {
            throw data;
        }
        if(data.channels === null)
        {
            data.channels = [];
        }
        return data;
    })
    .catch((error) => {
        throw error;
    });
});

export const postBusiness = createAsyncThunk("channels/postBusiness", ({body}) => {
    PageLoader.start();
    return fetch('/server/setup/business', { 
        method: "POST",
        headers : {
            "Content-Type" : "application/json"
        },
        body: JSON.stringify(body)
    })
    .then((response) => {
        PageLoader.stop();
        return response.json();
    })
    .then(data => {
        if (data.code === "NOT_ALLOWED" || data.status==="error") {
            throw new Error(data.message);
        }
        if(data.channels === null)
        {
            data.channels = [];
        }
        return data;
    })
    .catch((error) => {
        PageLoader.stop();
        throw error;
    });
});

export const updateBusiness=createAsyncThunk("channels/updateBusiness",
({body},{getState})=>
{
    PageLoader.start();
    const isMock = false;

    if(isMock){
      return new Promise((resolve,reject)=>
      {
        setTimeout(()=>
        {
          PageLoader.stop();
          resolve({data : body});
        },2000)
      })
    }
    else{
        let url = `/server/setup/business`;
    return fetch(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        })
        .then((response) => {
            PageLoader.stop();
            return response.json();
        })
        .then((data) => {
            if(data.status === "error"){
                throw data;
            }
            return { 
                data:body       
            };
        })
        .catch((error) => {
            PageLoader.stop();
            throw error;
        });
    }
});

export const fetchChannels = createAsyncThunk('channels/fetchChannels', () => {
    return fetch('/server/setup/channels')
        .then(res => {
            if(res.status === 204)
            {
                return { channels:[] };
            }
            else
            {
                return res.json();
            }
        })
        .then(data => {
            if(data.status === "error")
            {
                throw data;
            }
            return data.channels;
        })
        .catch(error => {
            throw error;
        });
});

export const postChannel = createAsyncThunk('channels/postChannel', (channelBody) => 
{
    PageLoader.start()
    return fetch('/server/setup/channels', { 
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(channelBody)
        })
        .then((response) => {
            PageLoader.stop()
            return response.json();
        })
        .then((data) => {
            if(data.status === "error"){
                throw data;
            }
            return data;
        })
        .catch((error) => {
            PageLoader.stop()
            throw error;
        });
});

export const updateChannel = createAsyncThunk('channels/updateChannel', ({ id, body, action=null }) => {
    const requestData = {
        method: "PUT"
    };

    if (body !== null) {
        requestData.headers = {
            "Content-Type": "application/json"
        };
        requestData.body = JSON.stringify(body);
    }

    PageLoader.start()
    let url = `/server/setup/channels/${id}`;
    if(action)
    {
        url += `?action=${action}`;
    }
    return fetch(url, requestData)
        .then(response => {
            PageLoader.stop()
            return response.json();
        })
        .then((data) => {
            if(data.status === "error"){
                throw data;
            }
            return { status: action === "activate" ? "active" : "inactive" };
        })
        .catch((error) => {
            PageLoader.stop()
            throw error;
        });
});
const channelSlice=createSlice({
    name:"channel",
    initialState:
    {
        channels:[],
        business:null,
        waba_id:null,
        businessLoading:false,
        postingBusiness:false,
        channelsLoading:true,
        updateLoading:false,
        updateBusinessLoading:false,
        error:null,
        timeFormat : ""
    },
    reducers:{
        resetChannelError:(state,action)=> {
            state.error=null
        },
    },
    extraReducers:builder=>{
        builder
        .addCase(fetchBusiness.pending, (state) => {
            state.businessLoading = true;
        })
        .addCase(fetchBusiness.fulfilled, (state, action) => {
            state.businessLoading = false;
            const business = action.payload;
            state.business = business;
            state.channels = business.channels;
            state.timeFormat = business.date_time_format;
            state.waba_id = business.waba_id;
        })
        .addCase(fetchBusiness.rejected, (state, action) => {
            state.businessLoading = false;
            const error = action.error;
            state.error={
                origin:"fetchBusiness",
                message:error,
              }
        })
        .addCase(postBusiness.pending, (state) => {
            state.postingBusiness = true;
        })
        .addCase(postBusiness.fulfilled, (state, action) => {
            state.postingBusiness = false;
            const business = action.payload;
            state.business = business;
            state.channels = business.channels;
            state.timeFormat = business.date_time_format;
            state.waba_id = business.waba_id;
        })
        .addCase(postBusiness.rejected, (state, action) => {
            state.postingBusiness = false;
            const error = action.error;
            state.error={
                origin:"postBusiness",
                message:error,
            }
        })
        .addCase(updateBusiness.pending,(state)=> {
            state.error=null;
            state.updateBusinessLoading = true;
        })
        .addCase(updateBusiness.fulfilled,(state,action)=> {
            const { data } = action.payload;
            state.updateBusinessLoading = false;
            state.business = {...state.business,...data};
        })
        .addCase(updateBusiness.rejected,(state,action)=> {
            const error=action.error;
            state.updateBusinessLoading = false;
            state.error={
              origin:"updateBusiness",
              message:error,
            }
        })
        .addCase(fetchChannels.pending,(state)=> {
            state.error=null;
            state.channelsLoading=true
        })
        .addCase(fetchChannels.fulfilled,(state,action)=> {
            state.channelsLoading=false;
            state.channels=action.payload.channels;
            state.business = action.payload.business;
            state.timeFormat = action.payload.business.date_time_format;
            state.waba_id=action.payload.waba_id;
        })
        .addCase(fetchChannels.rejected,(state,action)=> {
            state.channelsLoading=false;
            const error=action.error;
            state.error=
            {
              origin:"fetchChannels",
              message:error,
            }
        })
        .addCase(postChannel.pending,(state)=> {
            state.error=null;
        })
        .addCase(postChannel.fulfilled,(state,action)=> {
            state.channels.push(action.payload)
            state.waba_id=action.payload.waba_id
        })
        .addCase(postChannel.rejected,(state,action)=> {
            const error=action.error;
            state.error=
            {
              origin:"postChannel",
              message:error,
            }
            // state.error=action.error.message
        })
        .addCase(updateChannel.pending,(state)=>{
            state.error=null;
            state.updateLoading=true
        })
        .addCase(updateChannel.fulfilled, (state, action) => {
            const { id } = action.meta.arg;
            const channels = state.channels;
            state.updateLoading=false
            if (channels.length !== 0) {
                const channel = channels.find(channel => channel.id === id);
                if (channel) {
                    channel.status = action.payload.status;
                }
            }
        })        
        .addCase(updateChannel.rejected,(state,action)=>{
            state.updateLoading=false
            const error=action.error;
            state.error=
            {
              origin:"updateChannel",
              message:error,
            }
            // state.error=action.error.message
        })
    }
})

export const {resetChannelError} = channelSlice.actions;

export default channelSlice.reducer;