import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PageLoader from "../../../page loader/PageLoader";

const ORIGIN =  "/server/internal"

export const fetchTimeZone = createAsyncThunk("common/fetchTimeZone", () => {
  let url = `${ORIGIN}/constants/time_zones`;
  return fetch(url)
    .then((response) => {
      if (response.status === 204) {
        return { time_zones: [] };
      }
      return response.json();
    })
    .then((data) => {
      if (data.status === "error") {
        throw data;
      }
      return {data:data.time_zones}
    })
    .catch((error) => {
      throw error;
    });
});

export const fetchCountries = createAsyncThunk(
  "common/fetchCountries",
  (_, { getState }) => {
    const notification = getState().notification;
    const countries = notification.countries;
    if (countries && countries.length !== 0) {
      return Promise.resolve(countries);
    } else {
      return fetch(`/server/internal/constants/countries`)
        .then((response) => {
          if (response.status === 204) {
            return { countries: [] };
          } else {
            return response.json();
          }
        })
        .then((res) => {
          if (res.status === "error") {
            throw res;
          } else {
            return res.countries;
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  }
);

const commonSlice=createSlice({
    name:"common",
    initialState:
    {
        timeZones:[],
        countries: [],
    },
    extraReducers:builder=>{
        builder

        .addCase(fetchTimeZone.pending,(state)=> {
        })
        .addCase(fetchTimeZone.fulfilled,(state,action)=> {
            const {data} = action.payload
            state.timeZones = data
        })
        .addCase(fetchTimeZone.rejected,(state,action)=> {
            const error=action.error;
            state.error=
            {
              origin:"fetchTimeZone",
              message:error,
            }
        }) 
        .addCase(fetchCountries.pending, (state) => {
          //
        })
        .addCase(fetchCountries.fulfilled, (state, action) => {
          const countries = action.payload;
          state.countries = countries;
        })
        .addCase(fetchCountries.rejected, (state, action) => {
          state.error =
            action.error.message?.message || action.error.message.message;
        }); 
    }
})

export default commonSlice.reducer;
