import React, { useState } from 'react'
import cancelImg from "../../assets/Settings page/close.svg"
import english from "../../assets/literals/english.json";
import { setCommonSuccess } from "../../store/feature/user/currentUserSlice";
import { useDispatch } from 'react-redux';
import LocalLoading from '../../page loader/LocalLoading';
import showeye from "../../app assets/icon/passwordshow.png"
import hideeye from "../../app assets/icon/passwordhide.png"


const changepassword_content = english.changepassword_content;

const ChangePasswordForm = ({setChangepasswordFormOpen}) => {

    // State
    const [loading,setLoading] = useState(false);
    const [errorText,setErrorText] = useState("");
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [errors, setErrors] = useState({
        currentPasswordError :false,
        newPasswordError : false,
        confirmPasswordError :false,
        commonErrors : false
    });

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [ReEnterPassword, setReEnterPassword] = useState(false);

    // Dispatch
    const dispatch = useDispatch();

    const currentPasswordonChange = (value) =>{
        setCurrentPassword(value)
        setErrors((prevState) =>({
            ...prevState,
            currentPasswordError:false
        }))
    }

    const newPasswordonChange = (value) =>{
        setNewPassword(value)
        setErrors((prevState) =>({
            ...prevState,
            newPasswordError:false,
            commonErrors:false
        }))
    }

    const confirmPasswordonChange = (value) =>{
        setConfirmPassword(value)
        setErrors((prevState) =>({
            ...prevState,
            confirmPasswordError:false
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors("")

        if(currentPassword.trim() === ""){
            setErrors((prevState) =>({
                ...prevState,
                currentPasswordError:true
            }))
            setErrorText(changepassword_content.inputErrors.currentEmpty)
            return
        }

        if(newPassword.trim() === ""){
            setErrors((prevState) =>({
                ...prevState,
                newPasswordError:true
            }))
            setErrorText(changepassword_content.inputErrors.newEmpty)
            return
        }
       
        // Check for minimum length of 8 characters
        if (newPassword.length < 8) {
            setErrors((prevState) =>({
                ...prevState,
                commonErrors:true

            }))
            return
        }

       // Check for no more than 250 characters
       if (newPassword.length > 250) {
        setErrors((prevState) =>({
            ...prevState,
            commonErrors:true

        }))
       }

        // Check for at least one special character
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
            setErrors((prevState) =>({
                ...prevState,
                commonErrors:true

            }))
            return
        }

        // Check for at least one number
        if (!/\d/.test(newPassword)) {
            setErrors((prevState) =>({
                ...prevState,
                commonErrors:true

            }))
            return
        }

        // Check for both uppercase and lowercase letters
        if (!/[a-z]/.test(newPassword) || !/[A-Z]/.test(newPassword)) {
            setErrors((prevState) =>({
                ...prevState,
                commonErrors:true

            }))
            return
        }


        if(confirmPassword.trim() === ""){
            setErrors((prevState) =>({
                ...prevState,
                confirmPasswordError:true
            }))
            setErrorText(changepassword_content.inputErrors.newEmpty)
            return
        }

      // Check if new password matches the confirm password field
      if (newPassword !== confirmPassword) {
        setErrors((prevState) =>({
            ...prevState,
            confirmPasswordError:true,
        }))
        setErrorText(changepassword_content.inputErrors.duplicate)
        return

      }
        setLoading(true)
        window.catalyst.auth.changePassword(currentPassword, newPassword)
            .then((data) => {
              setLoading(true)
              if (data?.content?.error_code === "INVALID_INPUT") {
               throw  data
              } else {
                console.log(data);
                dispatch(setCommonSuccess(true))
                setLoading(false)
                setChangepasswordFormOpen(false)
                setCurrentPassword("")
                setNewPassword("")
                setConfirmPassword("")
              }
            })
            .catch((err) => {
           
            if(err.message === "Invalid Password"){
                setErrors((prevState) =>({
                    ...prevState,
                    currentPasswordError:true
                }))
                setErrorText(changepassword_content.inputErrors.invalidPassword)
            }

            if(err.message === "Password is same as loginname"){
                setErrors((prevState) =>({
                    ...prevState,
                    newPasswordError:true
                }))
                setErrorText(changepassword_content.inputErrors.sameloginName)
            }

            if(err.message === "Password present in the password history"){
                setErrors((prevState) =>({
                    ...prevState,
                    newPasswordError:true
                }))
                setErrorText(changepassword_content.inputErrors.samePassword)
            }

            if(err.message === "Password should not contain sequential characters"){
                setErrors((prevState) =>({
                    ...prevState,
                    newPasswordError:true
                }))
                setErrorText(changepassword_content.inputErrors.sequentialCharacters)
            }


              console.log("Password change incomplete.");
              setLoading(false)
              console.log(err);
            });
      
    };

    const formClose =  () =>{
       setChangepasswordFormOpen(false)
    }

    const showNewpassword = () =>{
        setShowNewPassword(pre => !pre)        
    }

    const showReEnterpassword = () =>{
        setReEnterPassword(pre => !pre)        
    }

  return (
    <>

    <div className='chnagepasword-main-container'>
        <div className="chnagepasword-container">
            <form className="changepassword-form-body" onSubmit={(e) =>{handleSubmit(e)}} >
                <div className="changepassword-sub-body">

                    <div className="change-password-heading">
                        <div className="change-password-title">
                        {changepassword_content.title}
                        </div>
                        <div className="change-password-cancel" onClick={() =>{formClose()}}>
                            <img src={cancelImg} alt="" />
                        </div>
                    </div>

                    <div className="change-password-body">
                        <div className="change-password-input-box">
                            <label htmlFor="current-password">
                                {changepassword_content.label.current}
                            </label>
                            <input 
                                type="text" 
                                id='current-password'
                                autoComplete="do-not-autofill"
                                value={currentPassword}
                                readOnly
                                onFocus={(e) => e.target.removeAttribute("readOnly")}
                                onChange={(e) => currentPasswordonChange(e.target.value)}
                                style={{border: errors.currentPasswordError && "1px solid red"}}
                            />
                            <p 
                            style={{opacity: errors.currentPasswordError && "1"}}
                            >
                                {errorText}
                            </p>
                        </div>
                        <div className="change-password-input-box">
                            <label htmlFor="new-password">
                                {changepassword_content.label.new}
                            </label>
                            <input
                            type={showNewPassword ? "text" : "password"}
                            id='new-password'
                            value={newPassword}
                            autoComplete='do-not-autofill'
                            readOnly
                            onFocus={(e) => e.target.removeAttribute("readOnly")}
                            onChange={(e) => newPasswordonChange(e.target.value)}
                            style={{border: errors.newPasswordError && "1px solid red"}}
                            />
                            <p style={{opacity: errors.newPasswordError && "1"}} >
                                {errorText}
                            </p>
                            <div className='form-eye-body' onClick={() =>{showNewpassword()}} >
                                {showNewPassword ?
                                    <img src={hideeye}/> 
                                    : 
                                    <img src={showeye} />
                                }
                            </div>
                        </div>
                        <div className="change-password-input-box">
                            <label htmlFor="confirm-password">
                                {changepassword_content.label.confrim}
                            </label>
                            <input
                            type={ReEnterPassword ? "text" : "password"}
                            id='confirm-password'
                            autoComplete='off'
                            readOnly
                            onFocus={(e) => e.target.removeAttribute("readOnly")}
                            value={confirmPassword}
                            onChange={(e) => confirmPasswordonChange(e.target.value)}
                            style={{border: errors.confirmPasswordError && "1px solid red"}}
                            />
                            <p
                            style={{opacity: errors.confirmPasswordError && "1"}}
                            >
                            {errorText}
                            </p>
                            <div className='form-eye-body' onClick={() =>{showReEnterpassword()}} >
                                {ReEnterPassword ?
                                    <img src={hideeye}/> 
                                    : 
                                    <img src={showeye} />
                                }
                            </div>
                        </div>
                    </div>
                    
                    {errors.commonErrors && !errors.currentPasswordError &&
                    !errors.newPasswordError && !errors.confirmPasswordError &&
                        <div className="change-password-alert-body">

                            <div className="">
                            <div className='change-password-alert-box-title'>
                                {changepassword_content.subTitle}
                            </div>
                            </div>
                            <div className="change-password-alert-box">
                                <div className="change-password-alert-colour"></div>
                                <div className="change-password-text">
                                    {changepassword_content.errors.uppercase}
                                </div>
                            </div>
                            <div className="change-password-alert-box">
                                <div className="change-password-alert-colour"></div>
                                <div className="change-password-text">
                                {changepassword_content.errors.minLength}
                                </div>
                            </div>
                            <div className="change-password-alert-box">
                                <div className="change-password-alert-colour"></div>
                                <div className="change-password-text">
                                {changepassword_content.errors.maxlength}
                                </div>
                            </div>
                            <div className="change-password-alert-box">
                                <div className="change-password-alert-colour"></div>
                                <div className="change-password-text">
                                {changepassword_content.errors.specialchar}
                                </div>
                            </div>
                            <div className="change-password-alert-box">
                                <div className="change-password-alert-colour"></div>
                                <div className="change-password-text">
                                {changepassword_content.errors.number}
                                </div>
                            </div>
                        </div>
                    } 
                    

                    <div className="change-password-button">
                        <button style={{width : loading && "80px"}}>
                        {loading ?   
                            <LocalLoading 
                                size='30px'
                                borderSize='3px'
                            /> 
                        : "Submit"
                        }  
                        </button>
                    </div>
                </div>
            </form>

        </div>
    </div>
   
    </>
  )
}

export default ChangePasswordForm