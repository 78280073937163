import { useState, useEffect } from "react";
import fcmManager from "./firebase";
import Toaster from "./Toaster";
import { useDispatch, useSelector } from "react-redux";
import NotificationHandler from "./notificationHandler";

const NotificationProvider = () => {
  const [toasts, setToasts] = useState([]);
  const [notificationHandler, setNotificationHandler] = useState(null);
  const { currentUser } = useSelector((state) => state.currentuser);
  const dispatch = useDispatch();

  const addToast = (toast) => {
    setToasts((prevToasts) => [...prevToasts, toast]);
    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.slice(1));
    }, 3000);
  };

  useEffect(() => {
    if (currentUser && !notificationHandler) {
      const handler = new NotificationHandler(dispatch, currentUser,addToast);
      setNotificationHandler(handler);
    }
  }, [currentUser, dispatch, notificationHandler]);

  useEffect(() => {
    if (notificationHandler) {
      fcmManager.initialize((message) => {
        console.log("received message >>> ", JSON.stringify(message, null, 2));
        const data = message.data;
        notificationHandler.handleNotification(data.notification);
      });
    }
  }, [notificationHandler]);

  useEffect(() => {
    if ("serviceWorker" in navigator && navigator.serviceWorker.controller && notificationHandler) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data?.type === "BACKGROUND_NOTIFICATION") {
          const message = event.data.payload;
          const data = message.data;
          console.log("received background message >>> ", JSON.stringify(message, null, 2));
          notificationHandler.handleNotification(data.notification);
        }
      });
    }
  }, [notificationHandler]);

  return (
    <div className="toast-container">
      {toasts.map((toast, index) => (
        <Toaster
          key={index}
          title={toast.title}
          description={toast.description}
          data={toast.data}
          status={toast.status}
          onClose={() =>
            setToasts((prevToasts) => prevToasts.filter((_, i) => i !== index))
          }
        />
      ))}
    </div>
  );
};

export { NotificationProvider };