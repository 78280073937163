import { useEffect, useState } from "react";
import PageLoader from "./page loader/PageLoader";
import { useDispatch, useSelector } from "react-redux";
import { userLogin, getAllUsers } from "./store/feature/user/currentUserSlice";
import { fetchBusiness } from "./store/feature/channels/channelSlice";
import { unSubscribeNotification } from "./store/feature/notification/notificationSlice";
import { setTimeZone } from "./store/feature/configs/configSlice";
import Notification from "./components/Notification";
import { enableIntegration } from "./store/feature/integrations/integrationSlice";
import { fetchCountries, fetchTimeZone } from "./store/feature/common/commonSlice";
import { NotificationProvider } from './notifications/NotificationProvider'
import LocalLoading from "./page loader/LocalLoading";
import AppRoutes from "./AppRoutes";
import Utils from "./utils/utils";
import PopupBusinessForm from "./components/tools components/PopupBusinessForm";
import CommonErrorPage from "./errors page /CommonErrorPage";

function App() 
{
  const dispatch = useDispatch();
  const { currentUser, currentUserLoading, updatedCurrentUser } = useSelector((state) => state.currentuser);
  const { channels:channelList } = useSelector((state) => state.channel);
  const [hasChannel, setHasChannel] = useState(false);
  const [channels, setChannels] = useState(channelList || []);
  const [loading, setLoading] = useState(true);
  const [appLoading, setAppLoading] = useState(true);
  const [ showBusinessForm, setShowBusinessForm] = useState(false);
  const [ internalError, setInternalError] = useState(false);

  const removeLoadingScreen = () => {
    const loadingScreen = document.getElementById('loading-screen');
    if (loadingScreen) 
    {
      loadingScreen.style.opacity = '0';
      setTimeout(() => loadingScreen.remove(), 500);
    }
  };

  useEffect(() => 
  {
    const messageListener = (event) => 
    {
      if (event.origin !== window.location.origin) return;
      if (event.data.type === 'auth-success') 
      {
        const data = event.data.payload;
        const { product, body } = Utils.getIntegrationBody(data);
        console.log(product,body)
        if(product && body && body.code)
        {
          dispatch(enableIntegration({product:product,body:body}));
        }
      } 
      else if (event.data.type === 'auth-error') 
      {
        console.error('Login failed:', event.data.payload);
      }
    };
    window.addEventListener('message', messageListener);
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  useEffect(() => 
  {
    removeLoadingScreen();
    setAppLoading(true)
    dispatch(userLogin())
    .unwrap()
    .then(() => {
      setAppLoading(false);
    })
    .catch((error) => {
      console.log(error);
    })

    const handleBeforeUnload = () => {
      dispatch(unSubscribeNotification({ topic: "chats" }));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      dispatch(unSubscribeNotification({ topic: "chats" }));
    };
  }, []);

  useEffect(() => {
    if (currentUser !== null && !currentUserLoading && !updatedCurrentUser) 
    {
      dispatch(fetchCountries());
      dispatch(fetchTimeZone());
      dispatch(fetchBusiness())
      .unwrap()
      .then((res) => {
        if(res.time_zone)
        {
          dispatch(setTimeZone(res.time_zone))
        }
        dispatch(getAllUsers())
        .unwrap()
        .then(() => {
          
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
        PageLoader.stop();
      })
      .catch((err) => {
        PageLoader.stop();
        console.log(err);
        if(err.code === "NOT_FOUND"){
          setShowBusinessForm(true);
        }
        if(err.code === "INTERNAL_ERROR"){
          setInternalError(true)
        }
      })
      .finally(() => {
        setLoading(false);
      });
    }
  }, [currentUser, currentUserLoading]);

  useEffect(() => {
    if (channelList && channelList.length !== 0) {
      setHasChannel(true);
      setChannels(channelList);
    }
  }, [channelList]);

  return (
    <div className="App">
      <PageLoader/>
      <Notification />   
      <NotificationProvider/>
      {appLoading && (
          <LocalLoading 
           size="80px"
          />
      )}
      
      {internalError && 
        <CommonErrorPage />
      }

      {!internalError && !loading && !currentUserLoading && currentUser?.first_name && (
          <AppRoutes
            channels={channels}
            currentUser={currentUser}
            hasChannel={hasChannel}
          />
      )}

      {!internalError && showBusinessForm && 
        <PopupBusinessForm 
          onClose = {() => setShowBusinessForm(false)} 
        />
      }

    </div>
  );
}

export default App;