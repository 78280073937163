import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PageLoader from '../../../page loader/PageLoader';

export const fetchChatRule=createAsyncThunk("rule/chatRule",(_,{getState})=>
{
    const {chatRule}=getState().rules;
    if(chatRule)
    {
        Promise.resolve(chatRule);
    }
    else
    {
        return fetch(`/server/setup/automation/chat_assignment_rule`).then(response=>
        {
            return response.json();
        })
        .then(data=>
        {
            if(data?.status==="error")
            {
                throw data;
            }
            else
            {
                return data;
            }
        })
        .catch(err=>
        {
            throw err;
        })

    }
})

export const updateChatRule=createAsyncThunk("rule/updateChatRule",({clauses})=>
{
    const isMock = false;
    PageLoader.start();
    if(isMock)
    {
        PageLoader.stop();
        return Promise.resolve(clauses);
    }
    else
    {
        return fetch('/server/setup/automation/chat_assignment_rule',
        {
            method:"PUT",
            headers:
            {
                "Content-Type":"Application/json"
            },
            body:JSON.stringify({clauses:clauses})
        })
        .then(res=>res.json())
        .then(response=>
        {
            PageLoader.stop();
            if(response.status==="error")
            {
                throw new Error(JSON.stringify({
                    key: response.key || "unknown_key",
                    code: response.code || "UNKNOWN_ERROR",
                    message: response.message || "An unknown error occurred."
                }));
    
            }
            else
            {
                return clauses
            }
        })
        .catch(error=>
        {
            PageLoader.stop();
            const parsedError = JSON.parse(error.message);
            throw JSON.stringify(parsedError);
        })
    }
})

export const fetchChattoScripts=createAsyncThunk("rule/fetchChattoScripts",({type = null},{getState})=>
{
    const {chatto_scripts}=getState().rules;
    
    if(type && chatto_scripts[type])
    {
        return Promise.resolve({type:type,data:chatto_scripts[type]});
    }
    else if(chatto_scripts["all"])
    {
        return Promise.resolve({type:"all",data:chatto_scripts["all"]});
    }
    else
    {
        let url = `/server/setup/automation/chatto_scripts`;
        if(type)
        {
            url += `?type=${type}`;
        }
        return fetch(url).then(response=>
        {
            if(response.status === 204)
            {
                return {
                    chatto_scripts:[],
                    info:
                    {
                        has_more: false
                    }
                };
            }
            return response.json();
        })
        .then(data=>
        {
            if(data?.status==="error")
            {
                throw data;
            }
            else
            {
                return { type:type || "all", data:data.chatto_scripts};
            }
        })
        .catch(err=>
        {
            throw err;
        })

    }
})

export const fetchChattoSingleScript=createAsyncThunk("rule/fetchChattoSingleScript",
({id},{getState})=>
{
        let url = `/server/setup/automation/chatto_scripts/${id}`;
        return fetch(url)
         .then ((response) =>{
            return  response.json()
         })
         .then((data) =>{
            if(data?.status==="error"){
                throw data;
            }
            else{
                return { 
                    id:id,
                    data:data
                };
            }
         }) 
         .catch((error) =>{
            throw error
         })
})

export const postChattoScript=createAsyncThunk("rule/postChattoScript",
({type,body},{getState})=>
{
        PageLoader.start();
        let url = `/server/setup/automation/chatto_scripts`;
        return fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
           })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              if (data.status === "error" || !data.id) {
                throw data;
              }
              return fetch('/server/setup/automation/chatto_scripts/'+data.id)
              .then ((response) =>{
                return  response.json();
             })
             .then((fetchedData) =>{
                PageLoader.stop();
                if(fetchedData?.status==="error"){
                    throw fetchedData;
                }
                else{
                    return { 
                        id:data.id,
                        type : type,
                        data:fetchedData
                    };
                }
             }) 
             .catch(error=>
            {
                throw error;
            })
            })
            .catch((error) => {
              PageLoader.stop();
              throw error;
            });
})

export const updateChattoScript=createAsyncThunk("rule/updateChattoScript",
({id,type,body},{getState})=>
{
        PageLoader.start();
        let url = `/server/setup/automation/chatto_scripts/${id} `;
        return fetch(url, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
           })
            .then((response) => {
              PageLoader.stop();
              return response.json();
            })
            .then((data) => {
                if(data.status === "error"){
                    throw data;
                }
                return { 
                    type:type,
                    id:id, 
                    data:body       
                };
            })
            .catch((error) => {
              PageLoader.stop();
              throw error;
            });
})

export const deleteChattoScript=createAsyncThunk("rule/deleteChattoScript",
    ({id,type},{getState})=>
    {        
        const isMock = false

        if(isMock){
            PageLoader.stop();
            return Promise.resolve({
                type:type,
                id:id  
            });
        }

        else {
            PageLoader.start();
            let url = `/server/setup/automation/chatto_scripts/${id} `;
            return fetch(url, {
                method: "DELETE"
                })
                .then((response) => {
                    PageLoader.stop();
                    return response.json();
                })
                .then((data) => {
                    if(data.status === "error"){
                        throw data;
                    }
                    return { 
                        type:type,
                        id:id       
                    };
                })
                .catch((error) => {
                    PageLoader.stop();
                    throw error;
                });
            } 
    })


const ruleSlice=createSlice({
    name:"rules",
    initialState:
    {
        chatRule:null,
        chatto_scripts:{},
        single_chatto_scripts :{},
        updateChatRuleLoading : false,
        chattoScriptsLoading:false,
        postChattoScriptScriptsLoading:false,
        updateChattoScriptScriptsLoading:false,
        singleChattoScriptLoading:false,
        chatRuleLoading:false,
        error:null
    },
    reducers :{
        resetRulesError:(state,action)=> {
            state.error=null
          },
    },
    extraReducers:(build)=>
    {
        build
        .addCase(fetchChatRule.pending,(state)=>
        {
            state.error=null;
            state.chatRuleLoading=true;
        })
        .addCase(fetchChatRule.fulfilled,(state,action)=>
        {
            state.chatRuleLoading=false;
            state.chatRule=action.payload;
        })
        .addCase(fetchChatRule.rejected,(state,action)=>
        {
            state.chatRuleLoading=false;
            state.error = {
                origin:"fetchChatRule",
                message:action.error
            };
        })

        .addCase(updateChatRule.pending,(state)=>
        {
            state.error=null;
            state.updateChatRuleLoading = true
        })
        .addCase(updateChatRule.fulfilled,(state,action)=>
        {
            const clauses=action.payload;
            state.updateChatRuleLoading = false
            state.chatRule={...state.chatRule,clauses:clauses};
        })
        .addCase(updateChatRule.rejected,(state,action)=>
        {
            const error=JSON.parse(action.error.message);
            state.updateChatRuleLoading = false
            state.error= {
                origin:"updateChatRule",
                message:
                {
                    key:error.key,
                    code:error.code,
                    message:error.message
                }
            };
        })

        .addCase(fetchChattoScripts.pending,(state)=>
        {
            state.error=null;
            state.chattoScriptsLoading=true;
        })
        .addCase(fetchChattoScripts.fulfilled,(state,action)=>
        {
            const { type, data } = action.payload
            state.chattoScriptsLoading=false;
            state.chatto_scripts[type] = data;
        })
        .addCase(fetchChattoScripts.rejected,(state,action)=>
        {
            state.chattoScriptsLoading=false;
            state.error=
            {
                origin:"fetchChattoScripts",
                message:action.error
            };
        })

        .addCase(fetchChattoSingleScript.pending,(state)=>
        {
            state.error=null;
            state.singleChattoScriptLoading=true;
        })
        .addCase(fetchChattoSingleScript.fulfilled,(state,action)=>
        {
            const { id, data } = action.payload
            state.single_chatto_scripts[id] = data;
            state.singleChattoScriptLoading=false;
        })
        .addCase(fetchChattoSingleScript.rejected,(state,action)=>
        {
            state.singleChattoScriptLoading=false;
            state.error= {
                origin:"fetchChattoSingleScript",
                message:action.error
            };
        })

        .addCase(postChattoScript.pending,(state)=>
        {
            state.error=null;
            state.postChattoScriptScriptsLoading = true
        })
        .addCase(postChattoScript.fulfilled,(state,action)=>
        {
            const { id, data,type } = action.payload
            state.postChattoScriptScriptsLoading = false
            const existingData = state.chatto_scripts[type] || []; 
            state.chatto_scripts[type] = [...existingData, data];
            state.single_chatto_scripts[id] = data;
            state.singleChattoScriptLoading=false;
        })
        .addCase(postChattoScript.rejected,(state,action)=>
        {
            state.postChattoScriptScriptsLoading = false
            state.error = {
                origin:"postChattoScript",
                message:action.error
            };
        })

        .addCase(updateChattoScript.pending,(state)=>{
            state.error=null;
            state.updateChattoScriptScriptsLoading = true;
        })
        .addCase(updateChattoScript.fulfilled,(state,action)=>{
            const { id, data,type } = action.payload
            const existingData = state.chatto_scripts[type] || []; 
            state.updateChattoScriptScriptsLoading = false;

            const index = existingData.findIndex(item => item.id === data.id);
            existingData[index] = { ...existingData[index], ...data };

            // Update the state
            state.chatto_scripts[type] = existingData;

            if(id in state.single_chatto_scripts);{
                delete state.single_chatto_scripts[id];
            }
            if(type === "onboarding")
            {
                if(state.chatto_scripts[type])
                {
                    delete state.chatto_scripts[type];
                }
            }
        })
        .addCase(updateChattoScript.rejected,(state,action)=>{
            state.updateChattoScriptScriptsLoading = false;
            state.error = {
                origin:"updateChattoScript",
                message:action.error
            };
        })
        
        // 

        .addCase(deleteChattoScript.pending,(state)=>{
            state.error=null;
        })
        .addCase(deleteChattoScript.fulfilled,(state,action)=>{
            const { id,type } = action.payload;
            if(state.chatto_scripts[type])
            {
                state.chatto_scripts[type] = state.chatto_scripts[type]?.filter((i) => i.id !== id);
            }
            if(id in state.single_chatto_scripts)
            {
                delete state.single_chatto_scripts[id];
            }
        })
        .addCase(deleteChattoScript.rejected,(state,action)=>{
            state.error = {
                origin:"deleteChattoScript",
                message:action.error
            };
        })
    }
})

export const {resetRulesError} = ruleSlice.actions;

export default ruleSlice.reducer;