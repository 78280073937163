import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';  
import App from './App';
import "./components/home page/home.css";
import './index.css';
import "./components/dynamic page/dynamicPage.css";
import './components/templates page/templatePage.css';
import './components/templates page/templateActionPage.css';
import './components/templates page/TemplateForm.css';
import './components/settings/settings.css';
import './components/Analytics page/analytics.css'
import './components/tools components/toolsComponents.css'
import "./page loader/load.css"
import "./errors page /errorpage.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
);