import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getReports = createAsyncThunk("analytics/getReports",({name,start,end,group_by=null})=>
{
    let url = `/server/analytics/${name}`;
    if(group_by)
    {
        url+=`?group_by=${group_by}`
    }
    if(start)
    {
        url+=group_by ? `&` : `?`;
        url+=`from=${start}`;
    }
    if(end)
    {
        url+=`&to=${end}`;
    }
    
    return fetch(url).then(response=>
    {
        return response.json();
    })
    .then(data=>
    {
        if(!Array.isArray(data[name]))
        {
            throw data;
        }
        return { name, group_by, data:data[name] };
    })
    .catch(error=>
    {
        throw error;
    })
})

const analyticSlice = createSlice({
    name : "analytics",
    initialState :
    {
        analytics : [],
        groupBy : null,
        current : null,
        analyticsLoading : false,
        error : null
    },
    reducers : 
    {
        resetCurrent:(state,action)=>
        {
            state.current = null;
            state.analytics = [];
        },
        resetData:(state,action)=>
        {
            state.analytics = [];
        },
        resetAnalyticsError:(state,action)=> {
            state.error=null
        },
    },
    extraReducers :(build)=>
    {
        build
        .addCase(getReports.pending,(state,action)=>
        { 
            const { name} = action.meta.arg
            state.current = name;
            state.analytics = [];
            state.analyticsLoading = true;
        })
        .addCase(getReports.fulfilled,(state,action)=>
        {
            const { name, data, group_by } = action.payload;            
            state.analyticsLoading = false;
            if(state.current == name)
            {
                if(group_by)
                {
                    state.groupBy = group_by;
                }
                state.analytics = data;
            }
        })
        .addCase(getReports.rejected,(state,action)=>
        {
            
            state.analyticsLoading = false;
            const error=action.error;
            state.error = {
                origin:"getReports",
                message:error,
            }
        });
    }
})

export const { resetCurrent, resetData,resetAnalyticsError } = analyticSlice.actions;
export default analyticSlice.reducer;