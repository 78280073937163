import React, { useEffect, useRef, useState } from 'react';
import tickImg from "../../app assets/icon/Vector.svg";
import arrowimg from "../../app assets/icon/drop-down-down.svg";
import { useSelector } from 'react-redux';

const TimeZoneDropDown = ({ timeZoneValue = "", name, onSelect = () => {} }) => {
  const { timeZones } = useSelector((state) => state.common);
  const [dropDown, setDropDown] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState('');
  const dropDownRef = useRef();

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) 
    {
      setDropDown(false);
    }
  };

  useEffect(() => {
    if(dropDown) 
    {
      document.addEventListener("mousedown", handleClickOutside);
    } 
    else 
    {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDown]);

  useEffect(() => {
    if (timeZoneValue) {
      setSelectedTimeZone(timeZoneValue);
    }
  }, [timeZoneValue]);

  const dropdownSelectedFn = (value) => {
    onSelect(name, value);
    setSelectedTimeZone(value);
    setDropDown(false);
  };

  const isMatching = (timeObj) => {
    return timeObj.code === selectedTimeZone;
  }

  return (
    <div className="time-zone-dropdown-container"  ref={dropDownRef}>
      <div
        className="time-zone-dropdown-value-box"
        onClick={() => setDropDown(!dropDown)}
      >
        <div className="time-zone-dropdown-value">
          {selectedTimeZone || "Select Time Zone"}
        </div>
        <div
          className="time-zone-dropdown-arrow"
          style={{ rotate: dropDown ? "180deg" : "0deg" }}
        >
          <img src={arrowimg} alt="arrow" />
        </div>
      </div>
      {dropDown && (
        <div className="time-zone-dropdown-dd-body">
          <div className="time-zone-dropdown-dd-list-body">
            {timeZones?.map((value, index) => (
              <div
                key={index}
                className="time-zone-dropdown-dd-list-value"
                onClick={() => {
                  dropdownSelectedFn(value?.code);
                }}
              >
                <div className='time-zone-dropdown-dd-list-value-content'>
                  {value?.offset?.label} {value?.name} {value?.code}
                </div>
                {isMatching(value) && (
                    <div className="time-zone-dropdown-dd-tick-box">
                      <img src={tickImg} alt="tick" />
                    </div>
                  )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeZoneDropDown;