import { configureStore } from '@reduxjs/toolkit';
import chatReducer from './feature/chat/chatSlice';
import currentUserReducer from './feature/user/currentUserSlice'
import templateReducer from './feature/templates/templateSlice'
import channelReducer from './feature/channels/channelSlice'
import contactReducer from './feature/contacts/contactSlice'
import notificationReducer from './feature/notification/notificationSlice'
import moduleReducer from './feature/modules/moduleSlice'
import recordReducer from './feature/records/recordSlice'
import configReducer from './feature/configs/configSlice'
import rulesReducer from './feature/rules/ruleSlice'
import analyticsReducer from './feature/analytics/analyticsSlice'
import integrationsReducer from './feature/integrations/integrationSlice'
import commonReducer from './feature/common/commonSlice';

export const store = configureStore({
  reducer: {
    chat : chatReducer,
    currentuser : currentUserReducer,
    template : templateReducer,
    channel : channelReducer,
    contact : contactReducer,
    notification : notificationReducer,
    module : moduleReducer,
    record : recordReducer,
    config : configReducer,
    rules : rulesReducer,
    analytics : analyticsReducer,
    integrations : integrationsReducer,
    common : commonReducer
  }
});

export default store;
