import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PageLoader from '../../../page loader/PageLoader';

const ORIGIN = `/server/setup`;
// Login thunk
export const userLogin = createAsyncThunk('currentuser/details', (_, { dispatch }) => {


  const userManagement = window.catalyst.userManagement;

  return userManagement.getCurrentProjectUser()
    .then(response => {
      if (response.code === 700) {
        window.location.href = "/__catalyst/auth/login";
      } else {
        if (response instanceof Response) {
          return response.json()
            .then(user => {
              dispatch(setCurrentUser(user));
              return user;
            });
        } else {
          // If response is already a JSON object
          dispatch(setCurrentUser(response.content));
          return response.content;
        }
      }
    })
    .catch(error => {
      console.log(error);
      window.location.href = "/__catalyst/auth/login";
      throw error;
    });
});

// Logout thunk
export const logout = createAsyncThunk('currentuser/logout', () => {
  const redirectURL = "/__catalyst/auth/login";
  const auth = window.catalyst.auth;

  return auth.signOut(redirectURL)
    .then(() => {
      console.log("logged out");
    })
    .catch((error) => {
      throw new Error("Error logging out >>> " + error.message);
    });
});

// Get all users
export const getAllUsers = createAsyncThunk('currentuser/getAllUsers', (_, { getState }) => {
  const { users } = getState().currentuser;

  if (users && users.length !== 0) {
    return Promise.resolve(users);
  }

  return fetch(`${ORIGIN}/users`)
    .then((response) => {
      // if (response.status === 204) {
      //   throw new Error("No users found");
      // }
      if (response.status === 204) {
        return {users:[]}
      }
      return response.json();
    })
    .then((data) => {
      if(data.status === "error"){
        throw data
      }
      else if(!data.users){
        throw({
          status:"error",
          message:`Invalid format >>>> ${data.users}`,
          code:"INVALID_FORMAT"    
        })
     }
     else{
      return data.users;
     }
      
    })
    .catch((error) => {
      throw error;
    });
});

// update
export const updateUsers = createAsyncThunk('currentuser/updateUsers', ({id,body}, { getState }) => {
  PageLoader.start();
  // return
  const isMock = false;

  if(isMock){
    return new Promise((resolve,reject)=>
    {
      setTimeout(()=>
      {
        PageLoader.stop();
        resolve({id,body});
      },2000)
    })
  }
  else {
    return fetch(`${ORIGIN}/users/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        PageLoader.stop();
        return response.json();
      })
      .then((data) => {
        if(data.status === "error"){
          throw data
        }
        return { id, body }
        
      })
      .catch((error) => {
        PageLoader.stop();
        throw error;
      });
  }  
});

// createUser
export const createUser = createAsyncThunk('currentuser/createUser', ({body}, { getState }) => {
  PageLoader.start();
  let url  =`${ORIGIN}/users`
  const isMock = false;
  if(isMock)
  {
    return new Promise((resolve,reject)=>
    {
      setTimeout(()=>
      {
        PageLoader.stop();
        resolve([...getState().currentuser.users,{...body,status:"active",id:Date.now()}]);
      },2000)
    })
  }
  else{
    return fetch(url,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify(body),
    })
    .then((response) => {
        PageLoader.stop();
        return response.json();
    })
    .then((data) => {
        if(data.status === "error"){
          throw data
        }
        return fetch(ORIGIN+'/users')
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if(data.status === "error"){
        throw data
      }
      else if(!data.users){
        throw({
          status:"error",
          message:`Invalid format >>>> ${data.users}`,
          code:"INVALID_FORMAT"    
        })
     }
     else{
      return data.users;
     }
      
    })
    .catch((error) => {
        PageLoader.stop();
        throw error;
    });
  }
});

// updateUserStatus
export const updateUserStatus = createAsyncThunk('currentuser/updateUserStatus', ({id,status}, { getState }) => {
  PageLoader.start();
  const isMock = false;
  if(isMock)
  {
    return new Promise((resolve,reject)=>
    {
      setTimeout(()=>
      {
        PageLoader.stop();
        resolve({id,status:status.value});
      },2000)
    })
  }
  else
  {
    let url = `${ORIGIN}/users/${id}?action=${status.label}`;
  
    return fetch(url,
    {
      method: "PUT"
    })
      .then((response) => {
        PageLoader.stop();
        return response.json();
      })
      .then((data) => {
        if(data.status === "error"){
          throw data
        }
        return { id,status:status.value }
        
      })
      .catch((error) => {
        PageLoader.stop();
        throw error;
      });
  }
});

// DeleteUser
export const deleteUser = createAsyncThunk('currentuser/deleteUser', ({id}, { getState }) => 
{
  PageLoader.start();
  const isMock = false;
  if(isMock)
  {
    return new Promise((resolve,reject)=>
    {
      setTimeout(()=>
      {
        PageLoader.stop();
        resolve({id});
      },2000)
    })
  }
  else
  {
    let url = `${ORIGIN}/users/${id}`;
  
    return fetch(url,
    {
      method: "DELETE"
    })
      .then((response) => {
        PageLoader.stop();
        return response.json();
      })
      .then((data) => {
        if(data.status === "error"){
          throw data
        }
        return { id }
        
      })
      .catch((error) => {
        PageLoader.stop();
        throw error;
      });
  }
});


const currentUserSlice = createSlice({
  name: "currentuser",
  initialState: {
    currentUser: null,
    users: [],
    currentUserLoading: true,
    updatedCurrentUser:false,
    createCurrentUserLoading:false,
    updateUserStatusLoading:false,
    updateUserLoading:false,
    deleteCurrentUserLoading:false,
    error: null,
    commonSuccess : {
      changepassword : false
    },
    usersloading:false,
  },
  reducers: {
    resetCurrentUserError:(state,action)=> {
      state.error=null
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setCommonSuccess :(state,action) =>{
      const value = action.payload
       state.commonSuccess.changepassword=value;
   },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.currentUserLoading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.currentUserLoading = false;
        state.currentUser = action.payload;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.currentUserLoading = false;
        state.currentUser = null;
        state.error = action.error.message;
      })

      // Fetch all users
      .addCase(getAllUsers.pending, (state) => {
        state.usersloading = true
        state.error=null;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.usersloading = false
        state.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.usersloading = false
        const error=action.error;
        state.error=
        {
          origin:"getAllUsers",
          message:error,
        }
        // state.error = action.error.message;
      })

      // updateUsers
      .addCase(updateUsers.pending, (state) => {
        state.error=null;
        state.updateUserLoading = true;
      })
      .addCase(updateUsers.fulfilled, (state, action) => {
        const { id, body } =action.payload;
        state.updateUserLoading = false;

        if (state.currentUser.user_id === id ) {
          if(state.currentUser)
          {
            state.currentUser = 
            {
              ...state.currentUser,
              role_details:
              {
                role_id : body.role.id,
                role_name : body.role.name
              }
            }
            state.updatedCurrentUser = true;
          }
        }


        state.users = state.users.map(user=>
        {
          if(user.user_id === id)
          {
            const updated = {...user,...body};
            return updated;
          }
          else
          {
            return user;
          }
        })
       
      })
      .addCase(updateUsers.rejected, (state, action) => {
        const error=action.error;
        state.updateUserLoading = false;
        state.error=
        {
          origin:"updateUsers",
          message:error,
        }
      })

       // createUser
       .addCase(createUser.pending, (state) => {
        state.error=null;
        state.createCurrentUserLoading = true
      })
      .addCase(createUser.fulfilled, (state, action) => {
        const users = action.payload;
        state.createCurrentUserLoading = false
        state.users = users;
      })
      .addCase(createUser.rejected, (state, action) => {
        const error=action.error;
        state.createCurrentUserLoading = false
        state.error=
        {
          origin:"createUser",
          message:error,
        }
      })

      // updateUserStatus
      .addCase(updateUserStatus.pending, (state) => {
        state.error=null;        
        state.updateUserStatusLoading = true
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        const { id, status } =action.payload;
        const userIndex = state.users.findIndex(user => user.id === id);
        state.updateUserStatusLoading = false
        if(userIndex !== -1){
          state.users[userIndex] = {
            ...state.users[userIndex], 
            status 
        };
        }
                
      })
      .addCase(updateUserStatus.rejected, (state, action) => {
        const error=action.error;
        state.updateUserStatusLoading = false
        state.error=
        {
          origin:"updateUserStatus",
          message:error,
        }
      })

      // deleteUser
      .addCase(deleteUser.pending, (state) => {
        state.error=null;
        state.deleteCurrentUserLoading = true
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const { id } =action.payload;
        state.deleteCurrentUserLoading = false
        state.users = state.users.filter(user => user.id !== id);
                
      })
      .addCase(deleteUser.rejected, (state, action) => {
        const error=action.error;
        state.deleteCurrentUserLoading = false
        state.error=
        {
          origin:"deleteUser",
          message:error,
        }
      });

  }
});

export const { resetCurrentUserError,setCurrentUser ,setCommonSuccess } = currentUserSlice.actions;
export default currentUserSlice.reducer;
