import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/feature/user/currentUserSlice";

// Icon
import logo from "../assets/header assets/chatzeal_logo.svg"


import english from "../assets/literals/english.json";
import ChangePasswordForm from "./settings/ChangePasswordForm";
import fcmManager from "../notifications/firebase";

const header_content = english.header_content;

const Header = ({ hasChannel, isActive = false }) => {

  // UseSelector
  const { currentUser} = useSelector((state) => state.currentuser);
  const notification = useSelector((state) => state.notification.notifications.chats_nav);

  // State
  const [chatNotification, setChatNotification] = useState(0);
  const [changepasswordFormOpen,setChangepasswordFormOpen] = useState(false)
  const [headerviewdropdown, setHeaderviewdropdownDropdown] = useState(false);

  //Dispatch ,useLocation , Ref
  const dispatch = useDispatch();
  const location = useLocation(); 
  const logoutDropdownref = useRef(null);

  // Variable
  let firstLetter = currentUser?.first_name.charAt(0).toUpperCase() || ""

  useEffect(() => {
    if (notification !== undefined && notification !== chatNotification) {
      setChatNotification(notification);
    }
  }, [notification, chatNotification]);

  const logoutOptionViewFn = () => {
    setHeaderviewdropdownDropdown(!headerviewdropdown);
  };

  const logoutHandle = () => {
    fcmManager.deleteToken();
    dispatch(logout()).unwrap().catch((error) => {
      console.log(error);
    });
  };

  const handleClickOutside = (event) => {
    if (
      logoutDropdownref.current &&
      !logoutDropdownref.current.contains(event.target)
    ) {
      setHeaderviewdropdownDropdown((prevState) => false);
    }
  };

  useEffect(() => {
    if (headerviewdropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [headerviewdropdown]);

  const openChangePasswordfn = () =>{
    setChangepasswordFormOpen(true)
  }

  return (
    <div className="header">
      <div className="brand">

        <div className="logo">
          <img src={logo} className="icon" alt="logo" />
          <p className="logotext">{header_content.brand_name}</p>
        </div>

        <div className="navbar">
          {header_content.routes.map((route, index) => (
            <div className="nav-child" key={index}>
              {route.path.startsWith("/chats") &&
                !location.pathname.startsWith("/chats") &&
                chatNotification !== 0 && (
                  <div className="header-notification-badge"></div>
                )}
              <Link
                to={route.path}
                className={`nav ${hasChannel && isActive ? "" : " disabled"} 
              ${
                (location.pathname === "/" &&
                  location.pathname === route.path) ||
                (location.pathname !== "/" &&
                  route.path !== "/" &&
                  location.pathname.startsWith(route.path))
                  ? "clicked"
                  : ""
              }`}
              >
                {route.name.charAt(0).toUpperCase() + route.name.slice(1)}
              </Link>
            </div>
          ))}
        </div>

      </div>

      <div className="header-acc-details-main-box">
        <div className="account">
          <div className="header-logout-box" onClick={logoutOptionViewFn} ref={logoutDropdownref} >
            
            <div className="header-profile-div">
              {firstLetter}
            </div>

            { headerviewdropdown && (
              <div className="header-drop-down-box">

                <div className="header-details-box">

                  <div className="header-details-letter">
                    {firstLetter}
                  </div>

                  <div className="header-details-name">
                    {currentUser?.first_name}
                  </div>

                  <div className="header-details-mail">
                    {currentUser?.email_id}
                  </div>

                </div>

                <div className="header-details-btn-box">
                  <button className="password-btn" onClick={() =>openChangePasswordfn()}>{header_content.change_password}</button>
                  <button className="logout-btn" onClick={() => {logoutHandle()}}>{header_content.logout_button_text}</button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {changepasswordFormOpen && 
        <ChangePasswordForm setChangepasswordFormOpen ={setChangepasswordFormOpen} />
      } 
      
    </div>
  );
};

export default Header;