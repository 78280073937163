import { initializeApp } from "@firebase/app";
import { getToken, getMessaging, onMessage } from "@firebase/messaging";

class FCMManager 
{
  constructor(config, vapidKey) 
  {
    if("serviceWorker" in navigator)
    {
      this.firebaseApp = initializeApp(config);
      this.messaging = getMessaging(this.firebaseApp);
    }
    this.vapidKey = vapidKey;
    this.tokenKey = "fcm_token";
  }

  initialize(callback) 
  {
    this.registerServiceWorker()
    .then((registration) => {
      return Notification.requestPermission();
    })
    .then((permission) => {
      if (permission === "granted") 
      {
        return this.setupToken();
      } 
      else 
      {
        this.deleteToken();
        console.warn("Notifications are disabled by the user.");
      }
    })
    .then(() => {
      this.setupOnMessage(callback);
    })
    .catch((error) => {
      if(error.cause?.code === "NOT_SUPPORTED")
      {
        console.warn("Firebase messaging is not supported by this browser");
      }
      else
      {
        console.error("Error initializing FCM Manager:", error);
      }
    });
  }
  

  registerServiceWorker() 
  {
      return new Promise((resolve,reject) => {
        if ("serviceWorker" in navigator)
        {
          navigator.serviceWorker
          .register('/app/firebase-messaging-sw.js',{scope:"/app/"})
          .then((registration) => {
            this.registration = registration;
            resolve(registration);
          })
          .catch((error) => {
            reject(error);
          });
        }
        else
        {
          reject(new Error("Service Worker is not supported in this browser.", { cause : { code : "NOT_SUPPORTED"}}));
        }
      });
  }

  async setupToken() {
    try 
    {
      const existingToken = localStorage.getItem(this.tokenKey);
      if (existingToken) 
      {
        // console.log("FCM Token already exists:");
      }
      else
      {
        const newToken = await getToken(this.messaging, {
          vapidKey: this.vapidKey,
          serviceWorkerRegistration: this.registration,
        });
  
        if (newToken) 
        {
          await this.postTokenToServer(newToken);
        } 
      }
    } catch (error) {
      console.error("Error setting up FCM token:", error);
    }
  }

  async postTokenToServer(token) {
    try 
    {
      const response = await fetch("/server/internal/registrations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          platform: "web",
        }),
      });

      if (response.status !== 201) {
        throw new Error("Failed to post FCM token to server.");
      }

      const data = await response.json();
      localStorage.setItem(this.tokenKey, token);
      return data;
    } catch (error) {
      console.error("Error posting FCM token to server:", error);
      throw error;
    }
  }

  async deleteToken() {
    try {
      const existingToken = localStorage.getItem(this.tokenKey);
      if (existingToken) {
        await fetch(`/server/internal/registrations/${existingToken}`, {
          method: "DELETE",
        });

        localStorage.removeItem(this.tokenKey);
      }
    } catch (error) {
      console.error("Error deleting FCM token:", error);
    }
  }

  setupOnMessage(callback) {
    onMessage(this.messaging, callback);
  }
}

const firebaseConfig = {
  apiKey: "AIzaSyAm2H4ASx6nHPCB1v6d3u3Ste9GBPZHXgo",
  authDomain: "chatzeal.firebaseapp.com",
  projectId: "chatzeal",
  storageBucket: "chatzeal.firebasestorage.app",
  messagingSenderId: "119050862471",
  appId: "1:119050862471:web:6f1e2b5418dd43aeec5d22"
};

const VAPID = "BCbmawXA7vqbtMKT1gf4AXBCr1rshR5arlgxNVvxgE6QEKwxCIzfwQkGiBhxeSL0E-vAuJWQCDsmOa7z-oGruxU";

const fcmManager = new FCMManager(firebaseConfig, VAPID);
export default fcmManager;