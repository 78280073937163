import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
  name: "config",
  initialState: {
    time_zone: null, // you can set a default time zone here if desired
    error: null,
    // timeFormat : "DD/MM/YYYY hh:mm:ss"
  },
  reducers: {
    // settimeFormat : (state,action) =>{
    //   state.timeFormat = action.payload;
    // },
    setTimeZone: (state, action) => {
      if (action.payload) {
        state.time_zone = action.payload;
        state.error = null; 
      } else {
        state.error = "Time zone payload is invalid";
      }
    }
    
  }
});

export const { setTimeZone, clearError } = configSlice.actions;
export default configSlice.reducer;
