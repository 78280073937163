import { DateTime } from "luxon";
import DateAndTime from "date-and-time";
import { isValidNumber, parsePhoneNumberWithError } from "libphonenumber-js";
import Validator from 'validator';

class Utils
{

  static dateTimeFormats = {
    DATE : "YYYY-DD-MM",
    TIME : "YYYY-DD-MM hh:mm:ss"
  };
  static colors = [
    {
      color : "#CDB330",
      background : "#FCFAE3"
    },
    {
        color : "#3D65FA",
        background : "#E6E9FF"
    },
    {
        color : "#C44A4C",
        background : "#FFD9DA"
    },
    {
        color : "#3BA3AE",
        background : "#D9F0FF"
    }
  ];

  static getDateTimeLabel(time, format) 
  {
    let label = time;
    if (label) 
    {
        const now = new Date();
        const inputDate = new Date(time);
        const todayStart = new Date(now.setHours(0, 0, 0, 0));
        const yesterdayStart = new Date(todayStart);
        yesterdayStart.setDate(todayStart.getDate() - 1);
        if (inputDate >= todayStart) 
        {
          return this.formatTime(time, format);
        } 
        else if (inputDate >= yesterdayStart) 
        {
          return "Yesterday";
        } 
        else 
        {
          return this.formatDate(time, format);
        }
    }
    return label;
  };


  static formatTime(dateString,timeFormat,onlyTime)
  {
    if(dateString == null || !timeFormat)
    {
      return null;
    }
    let format = timeFormat?.split(" ");
    if(onlyTime)
    {
      format = timeFormat;
    }
    else
    {
      if(!format || format?.length < 1)
      {
        format = "hh:mm:ss";
      }
      else
      {
        if(format?.length === 3)
        {
          format = format[1] + " " + format[2];
        }
        else
        {
          format = format[1];
        }
      }
    }
    const time = DateAndTime?.format(new Date(dateString),format);
    return time;
  }

  static formatDate(date, timeFormat,onlyDate) 
  {  
    
    if(date == null)
    {
      return null;
    }
    let format = timeFormat?.split(" ");
    if(onlyDate && timeFormat)
    {
      format = timeFormat;
    }
    else
    {
      if(!format || format?.length < 1)
      {
        format = "DD/MM/YYYY";
      }
      else
      {
        format = format[0];
      }
    }
    return DateAndTime.format(new Date(date),format);
  }

  static formatDateTime(timestamp,timeFormat) 
  {
    if(timestamp == null)
    {
      return null;
    }
    let format = timeFormat;
    if(format == null)
    {
      format = "DD/MM/YYYY hh:mm A";
    }
    const date = DateAndTime.format(new Date(timestamp),format);
    return date;
  }

  static createMessageBody(config,time_zone)
  {
      const { owner,chatId,content_type,content}=config;
      const tempId = `${chatId}-${Date.now()}`;
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().replace('T', ' ').substring(0, 19);
      const message = 
      {
          chat: {
              id:chatId
          },
          id: tempId,
          status: "pending",
          owner: {
              id:owner.id
          },
          content_type: content_type,
          content: content,
          time:formattedDate
      };
      return message;
  }

  static createNewMessageFromNotification(msg)
  {
      const newMessages=[];
      msg.chats.messages.forEach(message=>
      {
          const body = {
              chat: {
                  id:msg.chats.id
              }
          };
          Object.assign(body,message);
          newMessages.push(body);
      });
      return newMessages;
  }

  static convertToGMT(time, time_zone) 
  {
    if(time)
    {
      let normalizedTime = time;
      if (normalizedTime.match(/:\d{3}$/)) 
      {
          normalizedTime = normalizedTime.replace(/:(\d{3})$/, '.$1');
      }
      const format = normalizedTime.includes('.') ? 'yyyy-MM-dd HH:mm:ss.SSS' : 'yyyy-MM-dd HH:mm:ss';
      const timeInTimeZone = DateTime.fromFormat(normalizedTime, format, { zone: time_zone });
      const gmtTime = timeInTimeZone.setZone('UTC');
      return gmtTime.toFormat(format).replace(".",":");
    }
    else
    {
      return null;
    }
  }

  static convertToTimeZone(time, time_zone) 
  {
    if(time)
    {
      let normalizedTime = time;
      if (normalizedTime.match(/:\d{3}$/)) 
      {
          normalizedTime = normalizedTime.replace(/:(\d{3})$/, '.$1');
      }
      const format = normalizedTime.includes('.') ? 'yyyy-MM-dd HH:mm:ss.SSS' : 'yyyy-MM-dd HH:mm:ss';
      const gmtTime = DateTime.fromFormat(normalizedTime, format, { zone: 'UTC' });
      const timeInTimeZone = gmtTime.setZone(time_zone);
      return timeInTimeZone.toFormat(format);
    }
    else
    {
      return null;
    }
  }

  static getIntegrationBody(data)
  {
      if(!data) return null;
      const dataBody = {};
      let body = {};
      if(data.code)
      {
          body.code = data.code;
      }
      body.config = {};
      if(data.location)
      {
          body.config.dc_location = data.location;
      }
      if(data.shop)
      {
          body.config.shop = data.shop;
      }
      if(data.product)
      {
          dataBody.product = data.product;
      }
      dataBody.body = body;
      return dataBody;
  }

  static getTransformedData(parent, newEntries) 
  {
      let duplicateFound = false;
      let resultData = [];
      let duplicateCount = 0;
      if(!newEntries)
      {
          newEntries = [];
      }
      if (Array.isArray(parent) && Array.isArray(newEntries)) 
      {
          let parentCopy = [...parent];
          let newIds = newEntries.map((item) => item.id);
          duplicateFound = newIds.some((id) => parentCopy.some((parentItem) => parentItem.id === id));
          resultData = [...parentCopy];
          newEntries.forEach((item) => 
          {
              if (!parentCopy.some((parentItem) => parentItem.id === item.id)) 
              {
                  resultData.push(item);
              }
              else
              {
                  duplicateCount++;
              }
          });
      }
      return { duplicateFound, data: resultData,duplicateCount };
  }

  static getRandomColor()
  {
    const randomIndex = Math.floor(Math.random() * this.colors.length);
    return this.colors[randomIndex];
  }

  static toIncludeIntoChats(chat, view, isAssignee)
  {
      const { filter, unread } = view;
      let toInclude = false;
      const unreadCount = parseInt(chat.unread_count) || 0;
      const matchesUnread = !unread || unreadCount > 0;
      switch (filter) 
      {
        case "all_chats":
          toInclude = matchesUnread;
          break;
    
        case "open_chats":
          toInclude = chat.state === "open" && matchesUnread;
          break;
    
        case "my_chats":
          toInclude = isAssignee && matchesUnread;
          break;
    
        case "my_open_chats":
          toInclude = chat.state === "open" && isAssignee && matchesUnread;
          break;
    
        case "closed_chats":
          toInclude = chat.state === "closed" && matchesUnread;
          break;
    
        default:
          toInclude = false;
          break;
      }
      return toInclude;
  }

  static blobToBase64(blob)
  {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
  }

  static base64ToBlob(base64)
  {
      return new Promise((resolve, reject) => {
        try 
        {
          if (!base64) 
          {
            resolve(null);
            return;
          }
          const byteString = atob(base64.split(',')[1]);
          const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ab], { type: mimeString });
          resolve(blob);
        } 
        catch (error) 
        {
          console.log(error);
          reject(error);
        }
    });
  }

  static getParsePhoneNumber(mobile) 
  {
    if (mobile && isValidNumber(mobile)) 
    {
      let mobilenumber = parsePhoneNumberWithError(mobile);
      return mobilenumber;
    }
    else
    {
      return mobile;
    }
  }

  static formatPhoneNumber(mobile)
  {
    if(mobile)
    {
      mobile = this.getParsePhoneNumber(mobile);
      if(typeof mobile?.formatInternational === "function")
      {
        mobile = mobile.formatInternational();
      }
    }
    return mobile;
  }

  static debounce = (func, delay) => 
  {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  static isValidMobile(mobile)
  {
    return mobile && typeof mobile === "string" && isValidNumber(mobile) && Validator.isMobilePhone(mobile, null, { strictMode: true });
  }

  static isValidEmail(email)
  {
    return email && typeof email === "string" && Validator.isEmail(email);
  }

  static isValidURL(url, protocolCheck = false) 
  {
    if (!url || typeof url !== "string") 
    {
      return false;
    }
    const options = protocolCheck ? { require_protocol: true } : {};
    return Validator.isURL(url, options);
  }
  

  static isValidInt(int)
  {
    return int && typeof int === "string" && Validator.isInt(int);
  }

  static isValidTime(time)
  {
    return time && typeof time === "string" && !isNaN(Date.parse(time));
  }

  static isValidDate(date)
  {
    return date && typeof date === "string" && Validator.isDate(date, {strictMode:true,format:"YYYY-MM-DD"});
  }

  static getCurrentTime()
  {
    return DateAndTime.format(new Date(), this.dateTimeFormats.TIME);
  }
}

export default Utils;