import React, { useEffect, useState } from "react";
import ErrorAlert from "../alert messages/ErrorAlert";
import literal from "../assets/literals/english.json";
import InformationAlert from "../alert messages/InformationAlert";
import { useSelector } from "react-redux";
import SuccessAlert from "../alert messages/SuccessAlert";
const { notification_content } = literal;

const Notification = () => {
  const [errorMessage, setErrormessage] = useState("");
     const [record, setRecord] = useState({});
  const [successMessage,setSuccessMessage] = useState(" ")


  const chatError = useSelector((state) => state.chat.error);
  const ruleError = useSelector((state) => state.rules.error);
  const recordError = useSelector((state) => state.record.error);
  const moduleError = useSelector((state) => state.module.error);
  const channelError = useSelector((state) => state.channel.error);
  const templateError = useSelector((state) => state.template.error);
  const analyticsError = useSelector((state) => state.analytics.error);
  const currentUserError = useSelector((state) => state.currentuser.error);
  const templateCommonErrors = useSelector((state) => state.template.commonErrors);

  // handled locally not in redux :)
  const changePasswordError = useSelector((state) => state.currentuser.commonSuccess)

  useEffect(() =>{
    setErrormessage(notification_content.internalerror.internal_error)
  },[])

  // Error Notification
  useEffect(() => {
    setErrormessage(null);

    // ChatSlice Errors Handle if
    if (chatError) {
      // FetchChatViews
      if (chatError.origin === "fetchChatViews") {
        console.error("ChatError in FetchChatViews");
        if (chatError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // FetchChats
      if (chatError.origin === "fetchChats") {
        console.error("ChatError in FetchChats");
        if (chatError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // FetchSingleChat
      if (chatError.origin === "fetchSingleChat") {
        console.error("ChatError in FetchSingleChat");
        if (chatError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // FetchFilteredChats
      if (chatError.origin === "fetchFilteredChats") {
        console.error("ChatError in FetchFilteredChats");
        if (chatError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // FetchMessages
      if (chatError.origin === "fetchMessages") { 
        console.error("ChatError in FetchMessages");
        if (chatError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // UpdateChat
      if (chatError.origin === "updateChat") {
        console.error("ChatError in UpdateChatStatus");
        if (chatError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // SendMessage
      if (chatError.origin === "sendMessage") {
        console.error("ChatError in SendMessage");
        if (chatError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        } else if (chatError.message.code === "API_AUTH_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      //FetchStarredMessages
      if (chatError.origin === "fetchStarredMessages") {
        console.error("ChatError in FetchStarredMessages");
        if (chatError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      //FetchFilesInChat
      if (chatError.origin === "fetchFilesInChat") {
        console.error("ChatError in FetchFilesInChat");
        if (chatError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // FetchMedia
      if (chatError.origin === "fetchMedia") {
        console.error("ChatError in FetchMedia");
        if (chatError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // updateMessage
      // 1.Starred
      if (chatError.origin === "starred") {
        console.error("ChatError in updateMessage (Starred)");
        if (chatError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // 2.Reaction 
      if (chatError.origin === "reaction") {
        console.error("ChatError in updateMessage (Reaction)");
        if (chatError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        } else if (chatError.message.code === "INVALID_DATA") {
          setErrormessage(notification_content.reactionerror.invalid_data);
          return;
        }
      }

      // UpdateReadStatus
      if (chatError.origin === "updateReadStatus") {
        console.error("ChatError in UpdateReadStatus");
        if (chatError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }


      // FetchPreview
      if (chatError.origin === "fetchPreview") {
        console.error("ChatError in FetchPreview");
        if (chatError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }
    }

    // TemplateSlice Errors Handle if
    if(templateError){
        // FetchTemplates
        if (templateError.origin === "fetchTemplates") {
          console.error("TemplateError in FetchTemplates");
          if (templateError.message.code === "INTERNAL_ERROR") {
            setErrormessage(notification_content.internalerror.internal_error);
            return;
          }
        }

        // FetchSingleTemplate
        if (templateError.origin === "fetchSingleTemplate") {
          console.error("TemplateError in FetchSingleTemplate");
          if (templateError.message.code === "INTERNAL_ERROR") {
            setErrormessage(notification_content.internalerror.internal_error);
            return;
          }
        }

        // PostTemplate
        if (templateError.origin === "postTemplate") {
          console.error("TemplateError in PostTemplate");
          if (templateError.message.code === "INTERNAL_ERROR") {
            setErrormessage(notification_content.internalerror.internal_error);
            return;
          }
        }

        // EditTemplate
        if (templateError.origin === "editTemplate") {
          console.error("TemplateError in EditTemplate");
          if (templateError.message.code === "INTERNAL_ERROR") {
            setErrormessage(notification_content.internalerror.internal_error);
            return;
          }
        }

        // FetchTemplateMedia
        if (templateError.origin === "fetchTemplateMedia") {
          console.error("TemplateError in FetchTemplateMedia");
          if (templateError.message.code === "INTERNAL_ERROR") {
            setErrormessage(notification_content.internalerror.internal_error);
            return;
          }
        }

        // UpdateTemplateAction
        if (templateError.origin === "updateTemplateAction") {
          console.error("TemplateError in UpdateTemplateAction");

          if (["INTERNAL_ERROR","API_CONTRACT_MISMATCH","API_AUTH_ERROR","NOT_ALLOWED"].includes(templateError.message.code)) {
            setErrormessage(notification_content.internalerror.internal_error);
            return;
          }

          // if (templateError.message.code === "API_CONTRACT_MISMATCH") {
          //   setErrormessage(notification_content.internalerror.internal_error);
          //   return;
          // }


          // if (templateError.message.code === "API_AUTH_ERROR") {
          //   setErrormessage(notification_content.internalerror.internal_error);
          //   return;
          // }

          // if (templateError.message.code === "NOT_ALLOWED") {
          //   setErrormessage(notification_content.internalerror.internal_error);
          //   return;
          // }
        }
    }

    // ChannelSlice Errors Handle if
    if(channelError){

      // FetchBusiness
      if (channelError.origin === "fetchBusiness") {
        console.error("ChannelError in FetchBusiness");
        if (channelError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // PostBusiness
      if (channelError.origin === "postBusiness") {
        console.error("ChannelError in PostBusiness");
        if (channelError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

       // FetchChannels
       if (channelError.origin === "fetchChannels") {
        console.error("ChannelError in FetchChannels");
        if (channelError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // UpdateBusiness
      if (channelError.origin === "updateBusiness") {
        console.error("ChannelError in updateBusiness");
        if (channelError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // PostChannel
      if (channelError.origin === "postChannel") {
        console.error("ChannelError in PostChannel");
        if (channelError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // UpdateChannel
      if (channelError.origin === "updateChannel") {
        console.error("ChannelError in UpdateChannel");
        if (channelError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }
    }

    // CurrentUserSlice Errors Handle if
    if(currentUserError){
       // GetAllUsers
       if (currentUserError.origin === "getAllUsers") {
        console.error("CurrentUserError in GetAllUsers");
        if (currentUserError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      //  UpdateUsers
      if (currentUserError.origin === "updateUsers") {
        console.error("CurrentUserError in UpdateUsers");
        if (currentUserError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // CreateUser
      if (currentUserError.origin === "createUser") {
        console.error("CurrentUserError in createUser");
        if (currentUserError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // UpdateUserStatus
      if (currentUserError.origin === "updateUserStatus") {
        console.error("CurrentUserError in updateUserStatus");
        if (currentUserError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // DeleteUser
      if (currentUserError.origin === "deleteUser") {
        console.error("CurrentUserError in deleteUser");
        if (currentUserError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }
    }

    // ModuleSlice Errors Handle if
    if(moduleError){
      // FetchModules
      if (moduleError.origin === "fetchModules") {
        console.error("ModuleError in FetchModules");
        if (moduleError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // FetchModule
      if (moduleError.origin === "fetchModule") {
        console.error("ModuleError in FetchModule");
        if (moduleError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // FetchLayoutByName
      if (moduleError.origin === "fetchLayoutByName") {
        console.error("ModuleError in FetchLayoutByName");
        if (moduleError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

    }

    // RecordSlice Errors Handle if
    if(recordError){
      // FetchRecordByName
      if (recordError.origin === "fetchRecordByName") {
        console.error("recordError in FetchRecordByName");
        if (recordError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // FetchSearchRecordByName
      if (recordError.origin === "fetchSearchRecordByName") {
        console.error("recordError in FetchSearchRecordByName");
        if (recordError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // PostRecord
      if (recordError.origin === "postRecord") {
        console.error("recordError in PostRecord");
        if (recordError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
          if (recordError.message.code === "NOT_ALLOWED") {
            let message = notification_content.not_allowed_error.replace("{{field}}", recordError?.message?.info?.field);
            setErrormessage(message);
            setRecord(recordError?.message?.info?.record || {});
            return;
        }
      }

      // UpdateRecord
      if (recordError.origin === "updateRecord") {
        console.error("recordError in UpdateRecord");
        if (recordError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
          if (recordError.message.code === "NOT_ALLOWED") {
            setErrormessage(notification_content.not_allowed_error.replace("{{field}}", recordError?.message?.info?.field));
            setRecord(recordError?.message?.info?.record || {});
          return;
        }
      }

      // DeleteRecord
      if (recordError.origin === "deleteRecord") {
        console.error("recordError in DeleteRecord");
        if (recordError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // FetchSearchRecordByName
      if (recordError.origin === "fetchSearchRecordByName") {
        console.error("recordError in FetchSearchRecordByName");
        if (recordError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // PushRecordIntoIntegration
      if (recordError.origin === "pushRecordIntoIntegration") {
        console.error("recordError in PushRecordIntoIntegration",recordError);
        if (recordError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      if (recordError.origin === "updateCampaignStatus") {
        console.error("recordError in updateCampaignStatus",recordError);
        setErrormessage(recordError.message.message || notification_content.internalerror.internal_error);
        return;
      }

      // postMembers
      if (recordError.origin === "postMembers") {
        console.error("recordError in PostMembers",recordError);
        setErrormessage(recordError.message.message || notification_content.internalerror.internal_error);
        return;
      }

    }

    //RuleSlice Error handle if
    if(ruleError)
    {
      // get chatrule
      if (ruleError.origin === "fetchChatRule") {
        console.error("ruleError in fetchChatRule");
        if (ruleError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // update chat rule
      if (ruleError.origin === "updateChatRule") {
        console.error("ruleError in updateChatRule");
        if (ruleError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
        if (ruleError.message.code === "UNKNOWN_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // get Chatto Scripts
      if (ruleError.origin === "fetchChattoScripts") {
        console.error("ruleError in fetchChattoScripts");
        if (ruleError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // get Single Chatto Script
      if (ruleError.origin === "fetchChattoSingleScript") {
        console.error("ruleError in fetchChattoSingleScript");
        if (ruleError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // Post Chatto Script
      if (ruleError.origin === "postChattoScript") {
        console.error("ruleError in postChattoScript");
        if (ruleError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // Update Chatto Script
      if (ruleError.origin === "updateChattoScript") {
        console.error("ruleError in updateChattoScript");
        if (ruleError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }

      // Delete Chatto Script
      if (ruleError.origin === "deleteChattoScript") {
        console.error("ruleError in deleteChattoScript");
        if (ruleError.message.code === "INTERNAL_ERROR") {
          setErrormessage(notification_content.internalerror.internal_error);
          return;
        }
      }
    }

    //AnalyticsError Error handle if
    if(analyticsError)
      {
        // get chatrule
        if (analyticsError.origin === "getReports") {
          console.error("analyticsError in fetchChatRule");
          if (analyticsError.message.code === "INTERNAL_ERROR") {
            setErrormessage(notification_content.internalerror.internal_error);
            return;
          }
        }
      }

    //Common Errors 
    if(templateCommonErrors){
      if(templateCommonErrors.ordering){
        setErrormessage(notification_content.templateButtonOrderError.invalid_order);
          return;
      }
    }

  }, [chatError,templateError,channelError,currentUserError,recordError,ruleError,templateCommonErrors.ordering,analyticsError]);

  // Success Notification
  useEffect(() =>{

   if(changePasswordError.changepassword) {
    setSuccessMessage(notification_content.changePasswordSuccess.ok)
   }

  },[changePasswordError])
  
  return (
    <>
      
      {changePasswordError.changepassword && (
        <SuccessAlert
          alertText={successMessage}
          values={true}
        />
      )}

      {(["API_AUTH_ERROR", "INTERNAL_ERROR", "INVALID_DATA"].includes(chatError?.message?.code) || 
       ["INTERNAL_ERROR","API_AUTH_ERROR","NOT_ALLOWED","API_CONTRACT_MISMATCH"].includes(templateError?.message?.code) ||
       ["INTERNAL_ERROR",].includes(channelError?.message?.code)  ||
       ["INTERNAL_ERROR",].includes(currentUserError?.message?.code) ||
       ["INTERNAL_ERROR",].includes(moduleError?.message?.code) ||
       ["INTERNAL_ERROR","UNKNOWN_ERROR"].includes(ruleError?.message?.code) ||
       (["INTERNAL_ERROR", "NOT_ALLOWED"].includes(recordError?.message?.code) || recordError?.origin === "updateCampaignStatus") ||
       ["INTERNAL_ERROR",].includes(analyticsError?.message?.code) ||
        templateCommonErrors.ordering ) && errorMessage &&
       <ErrorAlert alertText={errorMessage} record = {record} values={true} onClose={() => setRecord({})}/>
      }
    </>
  );
};

export default Notification;