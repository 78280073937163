import React, { useEffect, useState } from "react";
import "./alertmsg.css";
import tickimg from "../assets/alertmessage assets/checkmark.png";
import alertCancel from "../assets/alertmessage assets/cancel.png";
import { setCommonSuccess } from "../store/feature/user/currentUserSlice";
import { useDispatch } from "react-redux";


const SuccessAlert = ({ alertText, values }) => {
  const dispatch = useDispatch();
  const [successAlert, setsuccess] = useState(false);
  const [isHiding, setIsHiding] = useState(false); // Controls transition out
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (values === true) {
      setsuccess(values);
      setIsHiding(false); 
    }
  }, [values]);

  useEffect(() => {
    if (successAlert === true) {
      autoHideInformationMsg();
    }
  }, [successAlert]);

  const hideInformationMsg = () => {
    setsuccess(false);
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

     // Start the hiding transition
     setIsHiding(true);


     setTimeout(() => {
      dispatch(setCommonSuccess(false))
     }, 500); 
  };

  function autoHideInformationMsg() {
    const timerID = setTimeout(function () {
      setsuccess(false);
    }, 4000);
    // console.log("off");
    setTimeoutId(timerID);
  }

  return (
    <>
      <div className="alert-message-body">
        <div
        className={`alert-msg-sub-body success-body ${
          successAlert ? (isHiding ? "alert-msg-up" : "alert-msg-down") : ""
        }`}
        >
          <div className="alert-msg-icon">
            <img src={tickimg} alt="info" />
          </div>
          <div className="alert-msg-content">{alertText}</div>
          <div
            className="alert-msg-cancel"
            onClick={() => {
              hideInformationMsg();
            }}
          >
            <img src={alertCancel} alt="cancel" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessAlert;
