import React from 'react'
import literals from "../assets/literals/english.json";

const {notification_content} = literals

const CommonErrorPage = () => {
  return (
    <>
        <div className="error-page-body">
            {notification_content.internalerror.internal_error}
        </div>
    </>
  )
}

export default CommonErrorPage