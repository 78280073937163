
import React, { useEffect, useState } from 'react';
import "./alertmsg.css"
import infoicon from "../app assets/icon/infoBell.svg";
import alertCancel from "../app assets/icon/Close button.svg";


const InformationAlert = ({ alertText,values, list,onclose }) => {
    const [informationAlert, setInformationAlert] = useState(false);
    const [timeoutId,setTimeoutId] = useState(null)

    useEffect(()=>{
        if(values===true) 
        {
            setInformationAlert(values)
        }
    },[values])

    useEffect(()=>{

      console.log(informationAlert,"informationAlert value");

        if(informationAlert===true)
        {
            autoHideInformationMsg()
        }
    },[informationAlert])
    
    const hideInformationMsg = () =>{
        if(onclose)
        {
            onclose();
        }
        setInformationAlert(false)
        if(timeoutId){
            clearTimeout(timeoutId)
            setTimeoutId(null)
        }
    }

    function autoHideInformationMsg() { 
        return
       const timerID =  setTimeout(function () {
        if(onclose)
        {
            onclose();
        }
            setInformationAlert(false);
        }, 4000);
        // console.log("off");
        setTimeoutId(timerID)
    }

    return (
    <>
        <div className="alert-message-body">
            <div className={`information-body ${informationAlert ? 'alert-msg-down' : ''}`}>
                <div className="alert-msg-icon">
                    <img src={infoicon} alt="info" />
                </div>
                <div className='information-sub-body'>
                    <div className="alert-msg-title">{alertText}</div>
                    <div className='alert-list-body'>
                        {Array.isArray(list) && 
                        <div>
                            {list.map((item,index)=>
                            (
                                <div key={index} className='alert-msg-content' >{item}</div >
                            ))}
                        </div>}
                    </div>
                </div>
                <div className="alert-msg-cancel" onClick={() =>{hideInformationMsg()}}> 
                    <img src={alertCancel} alt="cancel" />
                </div>
            </div>
        </div>
    </>
    );
};

export default InformationAlert;

